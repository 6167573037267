import { Carousel } from '3d-react-carousal';
import Employee from '../assets/images/banner/employee.webp';
import whiteSpace from '../assets/images/banner/white-shape.svg';
import Layout from '../common/Layout';
import BlogList from '../components/blog/itemProp/BlogList';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';

import certificate1 from '../assets/images/Appriciations/NG Study.webp';
import certificate2 from '../assets/images/Appriciations/Management and Strategy Institute.webp';
import certificate3 from '../assets/images/Appriciations/6Sixma Study.webp';
import certificate4 from '../assets/images/Appriciations/EVS.webp';
import certificate5 from '../assets/images/Appriciations/UoL Innovation.webp';
import certificate6 from '../assets/images/Appriciations/Society Of computer Science.webp';
import certificate7 from '../assets/images/Appriciations/EVS Professional Training Institute.webp';
import certificate8 from '../assets/images/Appriciations/CAREER COUNSELING.webp';
import certificate9 from '../assets/images/Appriciations/Society of CS & SE.webp';
import certificate10 from '../assets/images/Appriciations/WorkShop On Python.webp';
import certificate11 from '../assets/images/Appriciations/BlockChain Technology & Its Applications.webp';
import certificate12 from '../assets/images/Appriciations/FreeLancing WorkShop.webp';

import slider1 from '../assets/images/Appriciations/slider/slider1.webp';
import slider2 from '../assets/images/Appriciations/slider/slider2.webp';
import slider3 from '../assets/images/Appriciations/slider/slider3.webp';

import SEO from '../common/SEO';
import LazyLoad from "react-lazyload";

const certifications = [
  {
    link: 'EVS Professional Training Institute',
    ref: 'Certified ASP.net Core Specialist',
    img: certificate1
  }
  , {
    link: "UOL INNOVATION OLYMPIAD'19",
    ref: 'Website Development',
    img: certificate2
  }
  ,{
    link: 'Society Of computer Science (UOL)',
    ref: 'Web Development',
    img: certificate3
  }
  ,{
    link: 'EVS Professional Training Institute',
    ref: 'Certified ASP.net Core Specialist',
    img: certificate4
  }
  ,{
    link: "UOL INNOVATION OLYMPIAD'19",
    ref: 'Website Development',
    img: certificate5
  }
  ,{
    link: 'Society Of computer Science (UOL)',
    ref: 'Web Development',
    img: certificate6
  },
  {
    link: 'EVS Professional Training Institute',
    ref: 'Web Development',
    img: certificate7
  },
  {
    link: 'CAREER COUNSELING',
    ref: 'University Of Lahore',
    img: certificate8
  },
  {
    link: 'Society of CS & SE',
    ref: 'Web Development',
    img: certificate9
  },
  {
    link: 'WorkShop On Python',
    ref: 'University Of Lahore',
    img: certificate10
  },
  {
    link: 'BlockChain Technology & Its Applications',
    ref: 'University Of Lahore',
    img: certificate11
  },
  {
    link: 'FreeLancing WorkShop',
    ref: 'University Of Lahore',
    img: certificate12
  }
];

const Appreciation = () => {
  let slides = [
    <LazyLoad> <img src={slider1} alt='Certificate from Management and Institute' style={{ width: 'auto', height: 'auto' }} /></LazyLoad> ,
    <LazyLoad><img src={slider2} alt='Certificate from Management and Institute' style={{ width: 'auto', height: 'auto' }} /></LazyLoad>,
    <LazyLoad><img src={slider3} alt='Certificate from Management and Institute' style={{ width: 'auto', height: 'auto' }} /></LazyLoad>

  ];
  return (
    <>
      <SEO>
        <title>We Offer Best Certifications and Courses</title>
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/appreciation' />
      </SEO>
      <Layout>
        {/* Start Slider Area  */}
        <div className='slider-area slider-style-6 shape-left inheader-not-transparent height-550'>
          <div className='container'>
            <div className='row row--30 align-items-center'>
              <div className='order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30'>
                <div className='inner text-start'>
                  <h1 className='title color-white'>
                    Our Business Appreciation
                  </h1>
                  <p className='appreciate_p'>
                    Certification is not just a piece of paper; It's proof that
                    we've worked hard, learned a lot, and are ready to do great
                    things in our area of expertise- Owais Ahmad Khan
                  </p>
                  <p className='appreciate_p'>
                    Our CEO has wide range of certifications not only
                    demonstrates his dedication to staying current in field but
                    also reflects his passion for excellence. These
                    certifications serve as a testament to his expertise and
                    leadership in guiding our organization to success.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 order-1 order-lg-2'>
                <div>
                  <LazyLoad><img src={Employee} alt='Zai Systems Appreciation' style={{ width: 'auto', height: 'auto' }} /></LazyLoad>
                </div>
              </div>
              <div className='shape-image' >
              <LazyLoad> <img src={whiteSpace} alt='appreciation' style={{ width: 'auto', height: 'auto' }} /></LazyLoad>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Slider Area  */}
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mt-5 '>
              <SectionTitle
                textAlign='text-center'
                radiusRounded=''
                subtitle='Certificates'
                title='Certificates'
              // description="Certificates"
              />
            </div>
            <div className='col-lg-12 mt-5'>
              <div >
                <Carousel
                  slides={slides}
                  autoplay={true}
                  interval={2000}
                  arrows={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Certificates'
                  title='Certificates'
                />
              </div>
            </div>
            <div className='row row--15 '>
              {certifications.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
                >
                  <BlogList
                    StyleVar='box-card-style-default'
                    data={item}
                    certicate={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Appreciation;
