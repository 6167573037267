import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
// import { FiHeadphones, FiMail } from 'react-icons/fi';
import whatsappQR from '../../assets/images/contact/scanner-01.webp';
import LazyLoad from "react-lazyload";

const CalltoActionTwo = () => {
  return (
    <div className='rn-callto-action clltoaction-style-default style-5'>
      <div className='container'>
        <div className='row row--0 align-items-center content-wrapper theme-shape'>
          <div className='col-lg-4'>
            <div className='inner'>
              <div className='content text-left'>
                <h3>Get in Touch</h3>
                <p className=''>
                  You will find yourself working in a true partnership that
                  results in an incredible experience, and an end product that
                  is the best.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='inner'>
              <div
                style={{ justifyContent: 'center' ,marginTop:'20px',marginBottom:'20px'}}
                className='content text-center d-flex wrap-content'
              >
                 {/*<ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                  <div className='rn-address bg-transparent'>
                    <div className='icon'>
                      <FiMail />
                    </div>
                    <div className='inner'>
                      <p>
                        <a href='mailto:zaisystems@gmail.com'>
                          zaisystems@gmail.com
                        </a>
                      </p>
                    </div>
                  </div> 
                </ScrollAnimation>*/}

               {/* <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                   <div className='rn-address bg-transparent'>
                    <div className='icon'>
                      <FiHeadphones />
                    </div>
                    <div className='inner'>
                      <p>
                        <a href='tel:+92 334-6906960' target="_blank" rel="nofollow">+92 334 6906960</a>
                      </p>
                    </div>
                  </div> 
                </ScrollAnimation>*/}
                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                  {/* <div className='rn-address bg-transparent'> */}
                    <div className='inner'>
                    <LazyLoad><img
                        src={whatsappQR}
                        alt='whatsapp QR code'
                        height={200}
                        width={200}
                      /></LazyLoad> 
                    </div>
                  {/* </div> */}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionTwo;
