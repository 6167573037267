import React from 'react';
import contactImage from '../../assets/images/contactMailsIcons/contact-image.webp'
import ContactLayout from '../../common/ContactLayout';
import SEO from '../../common/SEO';
import SectionTitle from '../sectionTitle/SectionTitle';
import Separator from '../separator/Separator';
import ContactOne from './ContactOne';
import LazyLoad from "react-lazyload";


const Contact = () => {
  return (
    <>
      <SEO>
        <title>ZAI SYSTEMS: Best IT Services Company | Contact</title>
        <meta
          name='description'
          content='Contact us if you want to know details of ZAI SYSTEMS products, services and courses.'
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/contact' />
      </SEO>
      <ContactLayout>
        {/* Start Slider Area  */}
        <div className='slider-area slider-style-1 bg-transparent variation-2 '>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-7 order-2 order-lg-1 mt_md--40'>
                <div className='inner text-start mt-5'>
                  <h1 className='title theme-gradient display-two '>
                    Contact Us
                  </h1>
                  <p className='description w-95'>
                    Whether you have a project in mind, need IT solutions, or
                    want to enhance your skills through professional training,
                    Zai Systems is your trusted partner. Our dedicated team is
                    here to assist you.
                  </p>
                  <p className='description'>
                    Contact us today and kickstart your IT profession towards
                    greatness!
                  </p>
                </div>
              </div>
              <div className='col-lg-5 order-1 order-lg-2'>
                <div className='thumbnail'>
                <LazyLoad><img
                    src={contactImage}
                    alt='Contact'
                    className='moveImage mt-0 mb-5'
                  /></LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <Separator />
        <div className='main-content'>
          {/* Start Contact Area  */}
          <div className='rwt-contact-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Contact Form'
                  title='Connect With Us'
                  description=''
                />
              </div>
              <ContactOne head />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
        <Separator />
        <div className='main-content'>
          {/* Start schedule meeting */}
          <div className='rwt-contact-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Schedule meet'
                  title='Schedule a Meeting'
                  description=''
                />
              </div>
              <div className="div-block-5">
                <div className="html-embed w-embed w-script">
                  <style>
                    {`
            .calendly-inline-widget {
              border-radius: 5px;
              overflow: hidden;
              min-width: 120px;
              height: 700px;
            
              filter: invert(80%) saturate(0);
              opacity: 0.85;
            }
            @media (max-width: 768px) {
              .calendly-inline-widget {
                margin-left: 15px;
                margin-right: 15px;
        

              }
              
            }
          `}
                  </style>
                  <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/zaisystems/30min"
                    data-processed="true"
                    style={{ position: 'relative' }}
                  >
                    <div className="calendly-spinner">
                      <div className="calendly-bounce1"></div>
                      <div className="calendly-bounce2"></div>
                      <div className="calendly-bounce3"></div>
                    </div>
                    <iframe
                      src="https://calendly.com/zaisystems/30min?embed_domain=www.abc.com&amp;embed_type=Inline"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      title="Select a Date & Time - Calendly"
                    ></iframe>
                  </div>
                  <script
                    type="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js"
                    async=""
                  ></script>
                </div>
              </div>
            </div>
          </div>
          {/* End schedule meeting */}
        </div>

        <Separator />


        <div className='main-content'>
          {/* Start Contact Area  */}
          <div className='rwt-contact-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 '>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Contact With Us'
                    title='Our Contact Address Here'
                    description=''
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </ContactLayout>
    </>
  );
};
export default Contact;
