import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import logoTwo from "../../assets/images/logo/logo-dark.webp";
import logoOne from "../../assets/images/logo/logo.webp";
import Logo from "../logo/Logo";


const callToActionData = {
  title: "Ready to start creating a standard website?",
  subtitle:
    "Ignite your IT concerns with Zai Systems - We go beyond software development, integrating technology with human expertise to provide intelligent business solutions for entrepreneurs and enterprises.",
  btnText: "Purchase Doob",
};

const CalltoActionSeven = () => {
  return (
    <div className="rn-callto-action clltoaction-style-default style-7">
      {/* <div className="container"> */}
        <div className="row row--0 align-items-center content-wrapper">
          <div className="col-lg-12 col-md-12">
            <div className="inner">
              <div className="content text-start">
                <Logo
                  image={logoOne}
                  image2={logoTwo}
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="subtitle" >
                    {callToActionData.subtitle}
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};
export default CalltoActionSeven;
