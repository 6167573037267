
import one from './../assets/images/CareerLogos/on demand IT support.webp';
import two from './../assets/images/CareerLogos/online training courses.webp';
import three from './../assets/images/CareerLogos/Flexible work arrangements.webp';
import four from './../assets/images/CareerLogos/team meetings.webp';
import five from './../assets/images/CareerLogos/Exclusive discounts.webp';
import six from './../assets/images/CareerLogos/Remote work stipend.webp';
import seven from './../assets/images/CareerLogos/Generous paid time off.webp';
import eight from './../assets/images/CareerLogos/Mentorship program.webp';
import nine from './../assets/images/CareerLogos/Recognition and rewards.webp';
import ten from './../assets/images/CareerLogos/regular wiy managers.webp';
import eleven from './../assets/images/CareerLogos/Conferences and workshops.webp';
import twelve from './../assets/images/CareerLogos/Mental health support.webp';
import thirteen from './../assets/images/CareerLogos/Professional development and career growth.webp';
import fourteen from './../assets/images/CareerLogos/Remote work technology.webp';
import fifteen from './../assets/images/CareerLogos/Global opportunities.webp';
import sixteen from './../assets/images/CareerLogos/IT training and certification programs.webp';
import seventeen from './../assets/images/CareerLogos/accessibility-and-inclusion.webp';
import eighteen from './../assets/images/CareerLogos/feedback-and-surveys.webp';



const career2 = [
    {
        id: 1,
        title: `Why Zai Systems is Your Dream Career Destination?`,
        para: `Zai Systems isn't just offering employment; it's presenting an exceptional opportunity to collaboratively 
        shape the future of digital and technology. We actively seek talented professionals like you, regardless of your
         background as a developer, designer, marketer, or analyst.`,
        para1: `Resonates with You? Here's Why Zai Systems Stands Out:`,
      text:(
        <>
          <span class='text-white'>Diversity and inclusion: </span>We are dedicated to fostering a culture of equality
           and belonging, both within our teams and across the broader tech ecosystem.
        </>
      ),
        text1: (
            <>
              <span class='text-white'>Continuous learning: </span>We provide opportunities for continuous learning 
              and growth, ensuring you stay ahead of the curve.
            </>
          ),
        text2:(
            <>
              <span class='text-white'>Personal development: </span>Your success is our priority. We offer enticing
               perks and programs to support your professional and personal growth.
            </>
          ),
          text3: (
            <>
              <span class='text-white'>Remote work flexibility: </span>Enjoy the benefits of regional flexibility
               with streamlined remote work options, allowing you to work from anywhere in the world. 
            </>
          ),
    }
]

const career3a = [
    {
        id: 1,
        title: `Our Offered Perks and Benefits`,
        para: `
        Culture. Collaboration. Innovation! We're here to support you in taking significant strides, exploring new opportunities, 
        and fostering avenues for career advancement.`,
    }

]
const career3 = [
    {
        id: 1,
        title: 'On-demand IT support',
        para: `Our dedicated IT team is available to assist our clients with any technical issues they may encounter. `,
        img: one
    },
    {
        id: 2,
        title: 'Online training courses',
        para: `We provide access to a wide range of online courses on various topics, from technical skills to soft skills. `,
        img: two
    },
    {
        id: 3,
        title: 'Flexible work arrangements',
        para: `We offer flexible work arrangements to help remote employees achieve a healthy work-life balance. `,
        img: three
    },

    {
        id: 4,
        title: 'Virtual team meetings',
        para: `Teams hold regular virtual meetings to collaborate on projects and stay connected. `,
        img: four
    },
    {
        id: 5,
        title: 'Exclusive discounts',
        para: `Take advantage of exclusive discounts on various high-quality products and services of your choice. `,
        img: five
    },
    {
        id: 6,
        title: 'Remote work stipend',
        para: `We offer a monthly stipend to help remote employees offset the costs of working from home. `,
        img: six
    }
    ,

    {
        id: 7,
        title: 'Generous paid time off',
        para: `We offer employees generous paid time off, including vacation time, sick leave, and personal days. `,
        img: seven
    },
    {
        id: 8,
        title: 'Mentorship program',
        para: `Get paired with an experienced mentor who can provide guidance and support your career growth. `,
        img: eight
    },

    {
        id: 9,
        title: 'Recognition and rewards',
        para: `We celebrate your achievements and contributions with meaningful recognition programs and rewards. `,
        img: nine
    },

    {
        id: 10,
        title: 'Manager One-on-Ones',
        para: `Managers meet regularly with their remote employees to discuss their progress, goals, and any concerns they may have. `,
        img: ten
    },
    {
        id: 11,
        title: 'Conferences and workshops',
        para: `Attend industry-specific conferences and workshops to expand 
        your knowledge network and stay up-to-date on the latest trends. `,
        img: eleven
    },
    {
        id: 12,
        title: 'Mental health support',
        para: `We prioritize your mental well-being. We provide workshops, resources, and support to help
        you manage stress and maintain a healthy balance.
        `,
        img: twelve
    },

    {
        id: 14,
        title: 'Remote work technology ',
        para: `We provide you with the tools and resources you need to work effectively remotely. `,
        img: fourteen
    },
    {
        id: 15,
        title: 'Global opportunities',
        para: `Expand your horizons and work with colleagues from different countries and cultures. `,
        img: fifteen
    },
    {
        id: 16,
        title: 'Tech Certification Programs',
        para: `Upskill and reskill with training 
        programs and certifications, staying ahead of the curve.`,
        img: sixteen
    },
    {
        id: 13,
        title: 'Professional development and career growth',
        para: `Zai Systems invests in its employees’ learning and development by providing them with access to various online courses, certifications, and workshops. `,
        img: thirteen
    },
    {
        id: 14,
        title: 'Accessibility and Inclusion',
        para: `We ensure that our products and services are accessible and inclusive for all users, creating a positive user experience, regardless of their abilities, preferences, or backgrounds.`,
        img: seventeen
    },
    {
        id: 15,
        title: 'Feedback and Surveys',
        para: `We collect and analyze feedback and surveys from our users to understand their needs, expectations, and satisfaction levels, and improve our offerings accordingly.`,
        img: eighteen
    }

]
const career4 = [
    {
        id: 1,
        title: `Fullstack Wordpress Engineer`,
        para: `As a Full Stack WordPress Engineer, your role involves overseeing the complete development lifecycle of WordPress
         websites and applications. From conceptualization through deployment, you'll utilize your proficiency in both frontend 
         and backend technologies to craft resilient, scalable, and visually engaging digital solutions.`
    },
    {
        id: 2,
        title: `Human Resource Manager`,
        para: `
        As the Human Resources Manager, you will play a crucial role in formulating and executing HR strategies that align with 
        the company's goals. Your responsibilities will encompass the supervision of all facets of HR operations, including talent
         acquisition, employee relations, performance management, and employee development.`
    },
    {
        id: 3,
        title: ` Technical Recruiter`,
        para: `In the role of Technical Recruiter, you will have a vital role in identifying, assessing, and onboarding proficient
         professionals to fulfill our organization's technical requirements. Your collaboration with hiring managers, formulation 
         of impactful recruitment strategies, and contribution to the overall triumph of our talent acquisition endeavors will be 
         instrumental.`
    },
    {
        id: 4,
        title: `Senior Fullstack Shopify Engineer `,
        para: `In the position of Senior Fullstack Shopify Engineer, you will have a central role in crafting, building, and sustaining 
        Shopify e-commerce websites. Utilizing your proficiency in both frontend and backend technologies, you will spearhead the creation 
        of resilient and scalable solutions that adhere to industry standards and provide outstanding user experiences.`
    },
    {
        id: 5,
        title: `Senior Email Marketer `,
        para: `In the role of Senior Email Marketer, your duties include formulating, executing, and refining email marketing strategies
         to efficiently connect with and engage our intended audience. `
    },
    {
        id: 6,
        title: `Senior Frontend Engineer `,
        para: `As a Senior Frontend Engineer, you will have a pivotal role in creating and deploying responsive, user-friendly, and 
        high-performance user interfaces through the utilization of React Js, Bootstrap, and Tailwind. Collaborating closely with 
        diverse teams, you will actively contribute to the progression and refinement of our web applications to ensure an outstanding 
        user experience.
        `
    },
    {
        id: 7,
        title: ` Business Development Executive (Sales Navigator)`,
        para: `
        As a Business Development Executive with expertise in LinkedIn Sales Navigator, you will play a pivotal role in broadening our
         clientele and fostering revenue expansion. The perfect candidate will demonstrate a profound comprehension of LinkedIn Sales 
         Navigator features and capabilities, along with a verified history of success in business development and acquiring clients.`
    },
    {
        id: 8,
        title: `Business Development Executive (Upwork)`,
        para: `In the position of Business Development Executive specializing in Upwork, you will be instrumental in propelling the advancement
         and triumph of Invozone. The optimal candidate will take charge of recognizing and securing business prospects on the Upwork
          platform, cultivating robust client connections, and attaining revenue objectives. `
    },
    {
        id: 9,
        title: `Graphic Designer `,
        para: `We seek a dynamic Graphic Designer to join our development team, focusing on creating visually engaging graphics for various
         platforms such as digital and print media, encompassing social media, websites, and marketing materials.`
    },
    {
        id: 10,
        title: ` UI/UX Designer`,
        para: `We are in search of a proactive UI/UX Designer to become a valuable member of our development team. The perfect candidate should
         exhibit teamwork, possess a passion for UI/UX responsibilities, and approach tasks with a customer-oriented mindset.`
    },
    {
        id: 11,
        title: ` ROR Developer`,
        para: `
        We are actively seeking a proactive ROR Developer to become a part of our development team. The perfect candidate will be responsible for
         crafting and sustaining resilient, scalable, and high-performance web applications utilizing Ruby on Rails.`
    },
    {
        id: 12,
        title: `Business Analyst `,
        para: `We are seeking a proactive Business Analyst to join our development team. The ideal candidate should demonstrate strong teamwork,
         a passion for business analysis responsibilities, and approach tasks with a customer-centric mindset. `
    },
    {
        id: 13,
        title: `Business Development Manager `,
        para: `We are actively seeking a proactive Business Development Manager to join our development team. The ideal candidate should embody strong 
        teamwork, possess a fervor for business development responsibilities, and approach tasks with a customer-centric mindset. `
    },
    {
        id: 14,
        title: `Senior .NET Developer `,
        para: `We are actively seeking a proactive Senior .NET Developer to become a valuable member of our development team. The ideal candidate should 
        demonstrate strong teamwork, have a fervor for .NET programming tasks, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 15,
        title: `Senior React Native Developer `,
        para: `
        We are seeking a proactive Senior React Native Developer to join our development team. The Senior React Native Developer will lead the creation 
        of cross-platform mobile applications using React Native.`
    },
    {
        id: 16,
        title: `iOS Developer `,
        para: `We are actively seeking a proactive iOS Developer to become a part of our development team. The iOS Developer will be responsible for the design, 
        development, and maintenance of applications for the iOS platform.`
    },
    {
        id: 17,
        title: `Android Developer `,
        para: `We are actively seeking a proactive Android Developer to become a part of our development team. The Android Developer will be responsible for 
        the design, development, and maintenance of applications tailored for the Android platform.`
    },
    {
        id: 18,
        title: `Unreal Environment Artist `,
        para: `
        We are actively seeking a proactive Unreal Environment Artist to become a valuable member of our development team. The ideal candidate will be 
        accountable for both artistic design and crafting visually impressive environments using Unreal Engine.`
    },
    {
        id: 19,
        title: `Email Marketing Manager `,
        para: `We are actively seeking a proactive Email Marketing Manager to join our business development team. The ideal candidate will be responsible
         for formulating and implementing impactful email marketing campaigns to enhance engagement and conversions. Additionally, they will focus on
          optimizing deliverability and analyzing performance metrics.`
    },
    {
        id: 20,
        title: `Email Copywriter `,
        para: `
        We are actively seeking a proactive Email Copywriter to become a valuable member of our development team. The ideal candidate will be responsible
         for devising and executing email marketing strategies and campaigns aimed at fostering customer engagement, retention, and driving revenue growth.`
    },
    {
        id: 21,
        title: `Python Django Designer `,
        para: `We are actively seeking a proactive Python Django Designer to become a valuable member of our development team. The ideal candidate will be
         responsible for designing, developing, and maintaining web applications utilizing the Python and Django framework.`
    },
    {
        id: 22,
        title: `Elixir Developer `,
        para: `
        We are actively seeking a proactive Elixir Developer to become an integral part of our development team. The ideal candidate should exhibit strong 
        teamwork, possess a fervor for Elixir-related tasks, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 23,
        title: `C++ Developer with Blockchain Experience `,
        para: `
        We are actively seeking a proactive C++ Developer with Blockchain Experience to become a valuable member of our development team. The ideal candidate 
        should demonstrate strong teamwork, have a passion for C++ and Blockchain-related tasks, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 24,
        title: `Unreal Engine Developer `,
        para: `
        We are actively seeking a proactive Unreal Engine Developer to become a valued member of our development team. The ideal candidate will be 
        responsible for designing, developing, and participating in the creation of interactive applications and games using Unreal Engine. 
        They will collaborate with cross-functional teams to craft engaging and immersive experiences.`
    },
    {
        id: 25,
        title: ` Golang Developer with Blockchain Experience `,
        para: `
        We are actively seeking a proactive Golang Developer with Blockchain Experience to become a valuable member of our development team. The ideal
         candidate should demonstrate strong teamwork, have a passion for Golang and Blockchain-related tasks, and approach responsibilities with a 
         customer-centric mindset.`
    },
    {
        id: 26,
        title: ` Global Sales Representative `,
        para: `
        We are actively seeking a proactive Global Sales Representative to become a valued member of our development team. The ideal candidate should 
        exhibit strong teamwork, possess a fervor for sales-related tasks, and approach responsibilities with a customer-centric mindset.
        `
    },
    {
        id: 27,
        title: `DevOps Engineer `,
        para: `We are actively seeking a proactive DevOps Engineer to become a valued member of our development team. The ideal candidate should 
        demonstrate strong teamwork, possess a passion for DevOps-related tasks, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 28,
        title: `Unity Developer `,
        para: `
        We are actively seeking a proactive Unity Developer to become a valued member of our development team. The ideal candidate should 
        demonstrate strong teamwork, possess a passion for tasks related to Unity, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 29,
        title: `Solution Architect  `,
        para: `We are actively seeking a proactive Solution Architect to become a valued member of our development team. The ideal candidate
         should exhibit strong teamwork, possess a passion for business-related tasks, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 30,
        title: ` Senior Java Developer`,
        para: `
        We are actively seeking a proactive Senior Java Developer to become a valued member of our development team. The ideal candidate should 
        demonstrate strong teamwork, possess a passion for programming-related tasks, and approach responsibilities with a customer-centric mindset.`
    },
    {
        id: 31,
        title: `Blockchain Research Analyst `,
        para: `
        We are actively seeking a proactive Blockchain Research Analyst to become a valued member of our development team. The ideal candidate should
        exhibit strong teamwork, possess a passion for tasks related to blockchain, and approach responsibilities with a customer-centric mindset.`
    }
]

export const CareerData = [
    {
        id: 1,
        career: 'career',
        //   list: career1,
        listAud: career2,
        listService: career3a,
        listRoute: career3,
        listTemp: career4,
        //   listWhy: career5a,
        //   listTools: career5,
        //   listCourse: career6a,
        //   listBenefit: career6,
        // listCertificate: career7
    }
]
