import frontend01 from "../assets/images/courses/front.webp"
import frontend02 from "../assets/images/courses/HTML.webp"
import frontend03 from "../assets/images/courses/CSS.webp"
import frontend04 from "../assets/images/courses/SASS.webp"
import frontend05 from "../assets/images/courses/Bootstrap.webp"
import frontend06 from "../assets/images/courses/JS.webp"
import frontend07 from "../assets/images/courses/jquery.webp"
import frontend08 from "../assets/images/courses/AJAX.webp"
import frontend09 from "../assets/images/courses/ReactJS.webp"
import frontend10 from "../assets/images/courses/Angular.webp"
import frontend11 from "../assets/images/courses/Vue.webp"
import frontend12 from "../assets/images/courses/Typescript.webp"


import backend01 from "../assets/images/courses/Backend.webp"
import backend02 from "../assets/images/courses/NodeJs.webp"
import backend03 from "../assets/images/courses/Python.webp"
import backend04 from "../assets/images/courses/C++.webp"
import backend05 from "../assets/images/courses/ASP.NET.webp"
import backend06 from "../assets/images/courses/JAVA.webp"
import backend07 from "../assets/images/courses/PhpLaravel.webp"
import backend08 from "../assets/images/courses/kotlin.webp"
import backend09 from "../assets/images/courses/ruby-on-rails.webp"


export const courseImg = [
  {
    img: frontend01
  },
  {
    img: frontend02
  },
  {
    img: frontend03
  },
  {
    img: frontend04
  },
  {
    img: frontend05
  },
  {
    img: frontend06
  },
  {
    img: frontend07
  },
  {
    img: frontend08
  },
  {
    img: frontend09
  },
  {
    img: frontend10
  },
  {
    img: frontend11
  },
  {
    img: frontend12
  }
]
export const BackendImages = [
  {
    img: backend01
  },
  {
    img: backend02
  },
  {
    img: backend03
  },
  {
    img: backend04
  },
  {
    img: backend05
  },

  {
    img: backend06
  },

  {
    img: backend07
  },

  {
    img: backend08
  },

  {
    img: backend09
  }
]
