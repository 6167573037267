import React from "react";
// import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Lazyload from "react-lazyload";
const BlogListTwo = ({ data, StyleVar }) => {
  return (
    <>
      <div className={`rn-card ${StyleVar}`}>
        <div className="inner">
          <div className="thumbnail">
            <Link
              to={process.env.PUBLIC_URL + `/portfolio/${data.portfolio}`}
              className="image"
            >
              <Lazyload><img
                  src={`${process.env.PUBLIC_URL}/${data.image}`}
                  alt="Blog"
                />
              </Lazyload>
            </Link>
          </div>
          <div className="content">
            <h4 className="title" style={{ textAlign: 'center' }}>
              <Link to={process.env.PUBLIC_URL + `/portfolio/${data.portfolio}`}>
                {data.title}
              </Link>
            </h4>
            <p className="descriptiion" style={{ textAlign: 'center' }}>{data.date} <br />
              <p style={{ textAlign: 'center', marginTop: '5px' }}> <span className="text-white">Tools: </span>{data.excerpt}</p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
BlogListTwo.propTypes = {
  data: PropTypes.object,
};
export default BlogListTwo;
