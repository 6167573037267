import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

import icon04 from '../../assets/images/icons/am_icon2.webp';
import icon01 from '../../assets/images/icons/am_icon3-1.webp';
import icon02 from '../../assets/images/icons/am_icon4.webp';
import icon05 from '../../assets/images/icons/am_icon5.webp';
import icon06 from '../../assets/images/icons/am_icon6.webp';
import icon03 from '../../assets/images/icons/ico-01-1.webp';
import LazyLoad from "react-lazyload";

const smbData = [
  {
    image: icon01,
    title: 'Top Rated Digital Agency',
    description: `Zai Systems is a top-rated web design and digital agency that offers reliable and 
    professional solutions for SMBs, ecommerce, and web agencies.`
  },
  {
    image: icon02,
    title: 'Certified Web Specialists',
    description: `Our W3C and ITIL certified team delivers high-quality solutions, 
    using 50+ technologies across various industries, with a 97% client retention rate.`
  },
  {
    image: icon03,
    title: '24 Hours of Work Support',
    description: `Our dedicated customer support team is COPC and Six Sigma certified, and available 24/7 to 
    assist you with any issues or queries seamlessly.`
  },
  {
    image: icon04,
    title: 'Affordable Transparent Prices',
    description: `Zai Systems offers super affordable prices with 100% transparency, which makes it 
    easier for SMEs and web agencies to sub-contract work.`
  },
  {
    image: icon05,
    title: 'Ability to take up any load',
    description: `Zai Systems enables customers to sub-contract any amount of work load by working 
    under a robust and efficient project management system.`
  },
  {
    image: icon06,
    title: 'Proven Code Standards',
    description: `Our code standards are rigorously tested for W3C and search engine compliance, and are widely
     used by reputable web companies globally. `
  }
];

const ServiceSix = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {smbData.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='icon'>
                <LazyLoad><img src={val.image} alt={val.title} /></LazyLoad>
              </div>
              <div className='content'>
                <h3
                  className='title w-600'
                  style={{ fontSize: '19px', fontWeight: '600' }}
                >
                  <Link
                    to='#service'
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h3>
                <p
                  className='description b1 color-gray mb--0'
                  dangerouslySetInnerHTML={{ __html: val.description }}
                  style={{textAlign:'center'}}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default ServiceSix;
