import React from "react";
import Slider from "react-slick";
// import HCM from '../assets/images/impluseHCM/HCM 360.webp';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BannerActivation } from '../utils/script';
import approval from '../assets/images/hcmSection/approval.webp';
import attendance from '../assets/images/hcmSection/attendance.webp';
import ess from '../assets/images/hcmSection/ess.webp';
import leaves from '../assets/images/hcmSection/leaves.webp';
import payroll from '../assets/images/hcmSection/payroll.webp';
import LazyLoad from "react-lazyload";

// import LazyLoad from "react-lazyload";
const HcmSection = () => {

  // const dataArray = [
  //   {
  //     id: 1,
  //     bold: `Take Control of Your Workforce`,
  //     text: 'Empowering HR, Engaging Employees, Boosting Productivity',
  //   },
  //   {
  //     id: 2,
  //     bold: `Recruitment and Onboarding`,
  //     text: ` Hire the best candidates and onboard them smoothly.`,
  //   },
  //   {
  //     id: 3,
  //     bold: `Payroll and Benefits`,
  //     text: `Process payroll accurately and manage benefits easily.`,
  //   },
  //   {
  //     id: 4,
  //     bold: `Performance Management`,
  //     text: `Set and track goals, and give feedback effectively.`,
  //   },
  //   {
  //     id: 5,
  //     bold: `Learning and Development : `,
  //     text: `Equip your employees with the skills they need to
  //      succeed with built-in training modules and learning management functionalities.`,
  //   },
  //   {
  //     id: 6,
  //     bold: `Employee Self-Service`,
  //     text: `Let employees update and access their own data and services.`,
  //   },
  //   {
  //     id: 7,
  //     bold: `Reporting and Analytics`,
  //     text: ` Get valuable insights into your workforce with reports and dashboards.`,
  //   },
  // ];
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  // };
  const dataArraySlider = [
    {
      id: 1,
      title: `Payrolls`,
      text: `Tailored payroll solutions that are adaptable to your specific requirements. Effortlessly transfer 
      salaries to bank accounts and upload wage files via Mudad with a single click. Moreover, seamlessly integrate 
      payroll data with accountant systems such as Qoyod or Odoo, among others.`,
      point1: `• Salary Transfers via Mudad`,
      point2: `• Contract-Based Payrolls`,
      point3: `• Export Capability for Local Banks`,
      point4: `• Generation of WPS Files`,
      point5: `• Customizable Excel Templates`,
      point6: `• Comprehensive Reporting Features`,
      img: payroll
    },
    {
      id: 2,
      title: `Attendance Tracking`,
      text: `Efficiently monitor employee attendance records and facilitate In/Out punches through either a mobile 
      application or fingerprint device. Additionally, streamline reporting processes and automate calculations for
       overtime, absences, and tardiness.`,
      point1: `• Overtime Management`,
      point2: `• Integration with Official Holidays`,
      point3: `• Mobile App Punch In/Out`,
      point4: `• Compatibility with Fingerprint Devices`,
      point5: `• Automated Absence and Tardiness Deductions`,
      point6: `• Flexible Work Shift Scheduling`,
      img: attendance
    },
    {
      id: 3,
      title: `Leave Management`,
      text: `Define and customize organizational leave policies to suit your specific requirements. Configure leave 
      balance calculations based on individual employee contracts and manage various leave types effectively.`,
      point1: `• Versatile Leave Policy Configuration`,
      point2: `• Flexible Balance Customization`,
      point3: `• Provision for Unpaid Leaves`,
      point4: `• Attachment Support for Leave Requests`,
      point5: `• Advance Salary Options`,
      point6: `• Leave Encashment Facilities`,
      img: leaves
    },
    {
      id: 4,
      title: `Employee Self-Services`,
      text: `Empower your workforce with over 10 self-service options and request functionalities. Enjoy a flexible workflow, 
      enabling easy customization and adjustment according to organizational needs.`,
      point1: `• Adaptable Workflow Processes`,
      point2: `• Diverse Array of Self-Service Features`,
      point3: `• Option to Enable/Disable Services as Required`,
      point4: `• Seamless Mobile App Request Submission`,
      point5: `• Customizable Workflow Structures`,
      point6: `• Prompt Notifications for Enhanced Communication`,
      img: ess
    },
    {
      id: 5,
      title: ` Approval Workflow`,
      text: `Flexible approval workflows designed to accommodate various organizational requirements, offering limitless approval 
      stages, statuses, and actions.`,
      point1: `• Unlimited Approval Hierarchies`,
      point2: `• Intuitive Configuration Process`,
      point3: `• Extensive Status Options`,
      point4: `• Tailored Workflows for Individual Services`,
      point5: `• Instant Notification System`,

      img: approval
    }
  ];
  return (
    <>
      {/* <div className='rwt-portfolio-details rn-section-gap'>
        <div className='container services-main-div'>
          <div className='row'>
            <div className='col-lg-12'>
              <SectionTitle
                textAlign='text-center'
                radiusRounded=''
                subtitle='OUR PRODUCT'
                title=' Human Capital Management (HCM)'
                description='Introducing Zai Systems Impulse HCM 360: the next-generation Human Capital Management platform designed to transform the way you manage your most valuable asset - your people.'
              />
            </div>
            <div className='col-lg-12'>
            <SectionTitle
              description='Take Control of Your Workforce Through Our Services : '
            />
          </div>

            <div className="row">
            <div className="top2 col-md-6 hcm_main_one">
              <div className='mt-4'>
                {dataArray.map((data) => (
                  <div key={data.id} className='containerStyle'>

                    <div className='mt-2 hcm_points'>
                      <p>
                      <span className='logoStyle'>
                        •
                      </span><span className='text-white'>{data.bold}</span>
                      <br />{data.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 hcm_main_two">
            <LazyLoad><img alt="HCM 360" src={HCM} className='imageStyle' /></LazyLoad>  
            </div>
          </div>
          </div>
        </div>
      </div>   */}
      <div className='rwt-portfolio-details rn-section-gap-top'>
        <div className='container services-main-div'>
          <div className='row'>
            <div className='col-lg-12'>
              <SectionTitle
                textAlign='text-center'
                radiusRounded=''
                subtitle='OUR PRODUCT'
                title='Impulse HCM 360°'
                description='Introducing Zai Systems Impulse HCM 360°: the next-generation Human Capital Management platform designed to transform the way you manage your most valuable asset - your people.'
              />
            </div>
          </div>
        </div>
      </div>
      <Slider
        className='slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow'
        {...BannerActivation}
      >

        {dataArraySlider.map((item) => (
          <div key={item} className='single-slide ' >
            <div
              className='height-410 '
            //  style={{
            //   background: `#0f0f11`
            //  }}
            >
              <div key={item.id} className="container">
                <div className="row slider_bottom" >
                  <div className="col-md-5 " >
                    <div className="hcm_main_one">
                    <h3 >{item.title}</h3>
                    <p style={{ textAlign: 'justify' }}>{item.text}</p>
                    {item.point1 && <p>{item.point1}</p>}
                    {item.point1 && <p>{item.point2}</p>}
                    {item.point1 && <p>{item.point3}</p>}
                    {item.point1 && <p>{item.point4}</p>}
                    {item.point1 && <p>{item.point5}</p>}
                    {item.point1 && <p>{item.point6}</p>}
                    </div>
                  </div>
                  <div className="col-md-7  hcm_main_two">
                  <LazyLoad><img
                      src={item.img}
                      alt={item.title}
                      className="imageStyle"
                      loading="lazy"
                    /></LazyLoad> 
                  </div>
                </div>

              </div>
            </div>
          </div>
        ))}
      </Slider>

    </>
  )
}

export default HcmSection

