import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import LazyLoad from "react-lazyload";

const Logo = ({image, image2}) => {
    return(
        <div className="logo">
 <Link to={process.env.PUBLIC_URL + "/"}>
            <LazyLoad><img className="logo-light" src={process.env.PUBLIC_URL + image} alt="logo" style={{height:'80px',width:'60px'}}/></LazyLoad>    
            <LazyLoad><img className="logo-dark" src={process.env.PUBLIC_URL + image2} alt="Logo" style={{height:'45px',width:'51px'}}/></LazyLoad>   
            </Link>
        </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
