import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoad from 'react-lazyload';
import teamMemberOne from '../../assets/images/team/team-01.webp';
import teamMemberSecond from '../../assets/images/team/ahmed.webp';
import teamMemberThird from '../../assets/images/team/team-29.webp';
// import locationIcon from '../../assets/images/team/location.svg';
const teamData = [
  {
    image: teamMemberOne,
    name: 'Huzaifa Ali',
    designation: 'Web Engineer',
    location: 'Pakistan',
    description:
      'Huzaifa Ali specializes in designing, developing, and testing web systems and software. He is proficient in using tools and languages such as HTML, CSS, JavaScript, React, Node.js, MongoDB, Git, and more. '
  },
  {
    image: teamMemberSecond,
    name: 'M Ahmed',
    designation: 'Full Stack Developer',
    location: 'Pakistan',
    description:
      ' M Ahmed handles both the front-end and back-end of our web applications. He has extensive experience in using various technologies and frameworks, such as HTML, CSS, JavaScript, React, Node.js, MongoDB, and more.'
  },
  {
    image: teamMemberThird,
    name: 'Izzah Malik',
    designation: 'SEO Analyst',
    location: 'Pakistan',
    description:
      'Izzah Malik optimizes our web pages and content for search engines and users. She has a keen eye for keywords, analytics, and trends, and uses tools such as Google Analytics, Google Search Console, SEMrush, and more.'
  }
];

const TeamOne = ({ column, teamStyle }) => {
  return (
    <div className='row row--15'>
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <ScrollAnimation
              animateIn='fadeInUp'
              animateOut='fadeInOut'
              animateOnce={true}
            >
              <div className='inner'>
                <figure className='thumbnail'>
                  <LazyLoad><img src={data.image} alt={data.name} />
                  </LazyLoad>
                </figure>
                <figcaption className='content'>
                  <h2 className='title'>{data.name}</h2>
                  <h3 className='subtitle theme-gradient'>
                    {data.designation}
                  </h3>
                  {/* <span className='team-form'>
                    <LazyLoad><img src={locationIcon} alt={data.location} /></LazyLoad>
                    <span className='location'>{data.location}</span>
                  </span> */}
                  <p className='description'>{data.description}</p>
                </figcaption>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamOne;
