import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";

const PortfolioItem = ({ portfolio }) => {
  console.log(portfolio.category);
  return (
    <div className='rwt-card'>
      <div className='inner'>
        <div className='thumbnail'>
          <figure className='card-image'>
            <Link
              to={process.env.PUBLIC_URL + `/courses/${portfolio.category}`}
            >
              <LazyLoad><img
                className='img-fluid '
                src={portfolio.portfolioImage}
                // eslint-disable-next-line no-useless-concat
                alt={portfolio.title}
              /></LazyLoad>
            </Link>
          </figure>
          <Link
            to={process.env.PUBLIC_URL + `/courses/${portfolio.category}`}
            className='rwt-overlay'
          ></Link>
        </div>
        <div className='content '>
          <h5 className='title mb--0 text-center'>
            <Link
              to={process.env.PUBLIC_URL + `/courses/${portfolio.category}`}
            >
              {portfolio.title}
            </Link>
          </h5>
          {/* <span className='subtitle b2 text-capitalize'>
            {portfolio.category}
          </span> */}
        </div>
      </div>
    </div>
  );
};
export default PortfolioItem;
