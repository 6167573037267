import React from "react";
import { slugify } from "../../utils";
import { Link } from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";
import LazyLoad from "react-lazyload";
const BlogDetailsContent = ({ data }) => {
  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient" style={{ fontSize: '49px' }}>{data.title}</h1>
                </div>
                <ul className="rn-meta-list">
                  <li>
                    <FiUser />
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        `/archive/${slugify(data.author)}`
                      }
                    >
                      {data.author}
                    </Link>
                  </li>
                  <li>
                    <FiCalendar />
                    {data.date}
                  </li>
                </ul>
                <div className="thumbnail  mt--60">

                  <LazyLoad><img
                      className="w-100 radius"
                      src={`${process.env.PUBLIC_URL}/${data.largeImage}`}
                      alt="Blog Images"
                      style={{ width: '80%', height: 'auto' }}
                    /></LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-content rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="content">
                {data.body.map((value, i) => {
                  return (
                    <div key={i} dangerouslySetInnerHTML={{ __html: value }} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogDetailsContent;
