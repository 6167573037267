import Separator from '../../elements/separator/Separator';
import LazyLoad from "react-lazyload";
import '../../assets/tailwind/aboutImpulseStyles.css'
const HeroFeature = ({ title, img, body, reverse }) => {
  return (
    <>
    
      <div className={`hero-feature ${reverse && 'hero-feature-reverse'}`}>
        <div className='hero-feature-img d-flex justify-content-center align-items-center'>
         <LazyLoad><img src={img} alt={title}/></LazyLoad> 
        </div>
        <div className='hero-feature-text d-flex justify-content-center  flex-column'>
          <h3>{title}</h3>
          <p>{body}</p>
        </div>
      </div>
      
      <Separator />
    </>
  );
};

export default HeroFeature;
