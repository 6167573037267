import emailjs, { init } from "@emailjs/browser";
import React, { useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function ContactForm({ props, formStyle }) {
  
  const form = useRef();
  init("8r6iUINi3pC_HOCoL");

  const handleSubmit = (e) => {
    
    e.preventDefault();
    emailjs
      .sendForm(
        "service_5hujz4g",
        "template_epxon3y",
        form.current,
        "8r6iUINi3pC_HOCoL"
      )
      .then(
        (result) => {
          // alert("Message sent Successfully");
          form.current.reset();
          toast.success("Message sent Successfully");
        },
        (error) => {
        }
      );
  };

  return (
    <div className="w-100 m-auto">
      <form className={`${formStyle}`} onSubmit={handleSubmit} ref={form}>
        <div className="form-group">
          <input type="text" name="fullname" placeholder="Your Name" required autoComplete="off"/>
        </div>

        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            required
            autoComplete="off"
          />
        </div>

        <div className="form-group">
          <input type="text" name="phone" placeholder="Phone Number" required autoComplete="off"/>
        </div>

        <div className="form-group">
          <input type="text" name="subject" placeholder="Subject" required autoComplete="off"/>
        </div>

        <div className="form-group">
          <textarea
            name="message"
            placeholder="Your Message"
            required
          ></textarea>
        </div>

        <div className="form-group">
          <button className="btn-default btn-large">Submit Now</button>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
}
export default ContactForm;
