import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import BlogPropTwo from '../components/blog/itemProp/BlogPropTwo';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';

import Typed from 'react-typed';
const PersonalPortfolio = () => {
  const handleLinkClick = () => {
    const googleDriveLink = 'https://drive.google.com/file/d/18pRLetRUD_i2fSEaBaKiAnFum07hh_u3/view?usp=drive_link';
    window.open(googleDriveLink, '_blank');
  };


  return (
    <>
      {/* <SEO title='Work Portfolio' /> */}
      <SEO>
        <title>Zai Systems | Our Services and clients feedback</title>
        <meta
          name='description'
          content="Discover Zai Systems' versatile portfolio, featuring expert IT services and courses. Get free consultation now!"
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/portfolio' />
      </SEO>
      <main className='page-wrapper'>
        <HeaderOne
          btnStyle='btn-small round btn-icon'
          HeaderSTyle='header-transparent'
        />
        {/* Start Slider Area  */}
        
        <div className='slider-area slider-style-6_2  inheader-not-transparent height-500'
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/NewPortfolio.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat', }}
        >
          <div className='container '>
            <div className='row row--30 align-items-center'>
              <div className='order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30'>
                <div className='inner text-start'>
                  <h1 className='title color-white'>
                    Our Work Portfolio
                    {/* <br /> */}
                    <Typed
                      showCursor={false}
                      className='portfolio-typed'
                      strings={['A Gallery of Our Expertise']}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>

                   {/* <p className='description '>
                    At Zai Systems, we are proud to showcase our portfolio of successful projects and satisfied
                     clients. We have delivered high-quality IT solutions across various domains and industries,
                      ranging from software development, web design, app development, graphic design, legacy
                       migration, and more. 
                  </p>  */}
                  <button className='btn-default port_btn' onClick={handleLinkClick}>Check Portfolio</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Slider Area  */}

     

        {/* <Breadcrumb
          title='The Easiest Way To Create Website <br /> Quick Copy & Make site.'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Blog List View'
        /> */}

        <div className='main-content'>
          {/* Start Blog Area  */}
          <div className='rn-blog-area rn-section-gap'>
            <div className='container'>
              <div className='row mt_dec--30'>
                <BlogPropTwo
                  column='col-lg-6 mt--30'
                  StyleVarProp='box-card-style-default card-list-view'
                />
              </div>
            </div>
          </div>
          {/* End Blog Area  */}
        </div>
        {/* Start Elements Area  */}
        <div className='rwt-callto-action-area rn-section-gapBottom'>
          <div className='wrapper'>
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default PersonalPortfolio;


