import React from 'react';
import BlogClassicData from '../data/blog/BlogList.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { useParams } from 'react-router-dom'

const BlogDetails = () => {
    // const blogId = parseInt(portfolio, 10)
    // const data = BlogClassicData.filter(blog => blog.portfolio === blogId);
    const { portfolio } = useParams();
  const filteredList = BlogClassicData.filter((item) => item.portfolio === portfolio);
    return (
        <>
       <SEO>
        <title>{filteredList[0].metaTitle}</title>
        <meta name='description' content={filteredList[0].description} />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href={window.location.href} />
      </SEO> 
            <Layout>
                <div className="rn-blog-details-area">
                    <BlogDetailsContent data={filteredList[0]}  />
                </div>
            </Layout>
        </>
    )
}
export default BlogDetails;