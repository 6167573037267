import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import teamMember01 from '../../assets/images/team/team-29.webp';
import teamMember02 from '../../assets/images/team/Alishba.jpg';
import teamMember03 from '../../assets/images/team/Sania.jpeg';
import teamMember04 from '../../assets/images/team/team-28.webp';
import teamMember05 from '../../assets/images/team/ahmedDev.jpg';
import teamMember06 from '../../assets/images/team/AhmadGrowth.jpeg';
import teamMember07 from '../../assets/images/team/Nabeel.jpg';
import teamMember08 from '../../assets/images/team/Muneeb.jpg';
import teamMember09 from '../../assets/images/team/Abdullah.jpeg';
import teamMember10 from '../../assets/images/team/AwaisAhmed.jpeg';
import teamMember11 from '../../assets/images/team/samra.webp';
import teamMember12 from '../../assets/images/team/asm.webp';

import LazyLoad from "react-lazyload";



const teamData = [
  {
    image: teamMember12,
    name: 'Asma Noor',
    designation: 'HR Manager'
  },
  {
    image: teamMember11,
    name: 'Samra Shafeeq',
    designation: 'Talent Hunter | Team Manager | Content Specialist'
  },
  {
    image: teamMember10,
    name: 'Awais Ahmad',
    designation: 'Project Coordinator'
  },
 
  {
    image: teamMember06,
    name: 'M Ahmad',
    designation: 'Growth Specialist'
  },
   {
    image: teamMember01,
    name: 'Izzah Malik',
    designation: 'SEO Analyst'
  },
 
 
  {
    image: teamMember03,
    name: 'Sania Nazir',
    designation: 'Business Developer'
  },

  {
    image: teamMember02,
    name: 'Alishba Javed',
    designation: 'Graphic Designer'
  },
   {
    image: teamMember04,
    name: 'Hareem Junaid',
    designation: 'Graphic Designer'
  },
  {
    image: teamMember07,
    name: 'Nabeel Bhatti',
    designation: 'SEO Analyst'
  },
  {
    image: teamMember05,
    name: 'Muhammad Ahmed',
    designation: 'MERN Stack Developer'
  },

  {
    image: teamMember09,
    name: 'Abdullah Alamgir',
    designation: 'Flutter Developer'
  },
  {
    image: teamMember08,
    name: 'Muneeb Naveed',
    designation: 'Graphic Designer'
  },
];

const TeamThree = ({ column, teamStyle }) => {
  return (
    <div className='row row--30'>
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`rn-team ${teamStyle}`}>
              <div className='inner'>
                <figure className='thumbnail'>
                  <LazyLoad><img src={data.image} alt={data.name} /></LazyLoad>
                </figure>
                <figcaption className='content text-center'>
                  <h2 className='title'>{data.name}</h2>
                  <h6 className='subtitle theme-gradient'>
                    {data.designation}
                  </h6>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default TeamThree;
