import React from 'react'
import HeaderOne from '../../../common/header/HeaderOne'
import FooterTwo from '../../../common/footer/FooterTwo'
import Copyright from '../../../common/footer/Copyright';
import { useParams } from 'react-router-dom'
import { ServiceData } from '../../../data/servicesdata'
import SEO from '../../../common/SEO';
import { Link } from "react-router-dom";
import CalltoActionFive from '../../calltoaction/CalltoActionFive';
import tick from './../../../assets/images/servicePagesLogos/logos/tick final.webp'
import LazyLoad from "react-lazyload";
const SCD = ({ textAlign, serviceStyle }) => {
  const { service } = useParams()
  const newList = ServiceData.filter((v) => v.service === service);
  console.log();
  return (

    <>
      <HeaderOne />
      <div className='rwt-portfolio-details rn-section-gap '>
        <div className='container services-main-div'>
          <div className='row'>
            {newList.map(
              ({

                list,
                listAud,
                listTemp,
                listService,
                listRoute,
                listTools,
                listBenefit,
                listWhy,
                listCourse,
                listCertificate,
                listadvantage,
                listcard,
                listcarddetails,
                listdata,
                listdatadetails,
                listHiring,
                listHiringDetails,
                listConclusion,
                listImages,
                listImagesDetails,
                listnftExtra,
                listnftExtraDetails,
                listForSeo
              }) => (
                <>

                  <SEO>
                    {listForSeo.map(({ id, title, content, link }) => (
                      <React.Fragment key={id}>
                        <title>{title}</title>
                        <meta name='description' content={content} />
                        <meta name='robots' content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' />
                        <link rel='canonical' href={link} />
                      </React.Fragment>
                    ))}
                  </SEO>
                  <div className='col-lg-12 '>
                    {/* service first */}
                    <div >
                      {list !== undefined &&
                        list.map(({ id, title, para, para1, img }) => (
                          <div className=" row">{/* service-one */}
                            <div className="top2 col-md-6 " key={id}>
                              {title && (
                                <h1 className="service-heading-one">
                                  {title}
                                </h1>
                              )}

                              <p className='para-one'>{para}</p>
                              <p className='para-one'>{para1}</p>
                              <div className="mt-6">

                                <Link to="/contact">
                                  <button class="btn-default mt-4">Get Free Consultation </button>
                                </Link>
                              </div>
                            </div>
                            <div className=" col-md-6">
                            <LazyLoad><img alt={title} src={img} /></LazyLoad>
                            </div>
                          </div>
                        ))}
                    </div>

                    {/* service second */}
                    {/* 
                    <div className=" service-two ">
                      {listAud !== undefined &&
                        listAud.map((i) => (
                          <div key={i.id}>
                            <h2 className="service-heading-two">
                              {i.title}
                            </h2>
                            <p className="para-two">
                              {i.para}
                            </p>
                            <p className="para-two">
                              {i.para1}
                            </p>
                            <p className="para-two">
                              {i.para2}
                            </p>
                          </div>
                        ))}

                    </div> */}
                    <div className="service-two">
                      {listAud !== undefined &&
                        listAud.map((i) => (
                          <div key={i.id}>
                            {i.title && <h2 className="service-heading-two">{i.title}</h2>}
                            {i.para && <p className="para-two">{i.para}</p>}
                            {i.para1 && <p className="para-two">{i.para1}</p>}
                            {i.para2 && <p className="para-two">{i.para2}</p>}
                          </div>
                        ))}
                    </div>
                    {/* for extra data */}
                    <div className="row mt-0">
                      {listdata !== undefined &&
                        listdata.map((i) => (
                          <>
                            <h2 key={i.id} className='extra-heading'> {i?.title1}</h2>
                            {/* <div className="col-md-10 mx-auto text-center"> */}
                            <p key={i.id} className='extra-text'> {i?.para}</p>
                            <p key={i.id}> {i?.para1}</p>
                            {/* </div> */}

                          </>
                        ))}


                      {listdatadetails !== undefined &&
                        listdatadetails.map((service) => {
                          return (
                            < div className='col-md-11 mx-auto text-center' >
                              <p className='extra-text2'>
                                {/* <i class="fa-solid fa-angle-right "></i> &nbsp; */}
                                <span>{service?.para}</span>
                              </p>
                            </div>
                          )
                        })}


                    </div>
                    {/* service third */}

                    {/* {listTemp !== undefined &&
                    listTemp.map((i) => (
                      <div className='service-three'> <div key={i.id}>
                        <div>
                          <h1 className=" service-three-head mt-5">{i.title}</h1>
                        </div>
                        <div>
                          <button class="btn-default talk">
                            Talk to Our Experts
                          </button>
                        </div>

                      </div>
                      </div>
                    ))} */}

                    {/* service cards fourth  */}
                    <div className='row '>
                      {listService !== undefined &&
                        listService.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'>
                              {i?.title}
                            </h2>
                            {i.para && <p className="card-text">{i.para}</p>}
                            {i.para1 && <p className="card-text">{i.para1}</p>}
                          </>
                        ))}
                      <div className="row upper">
                        {listRoute !== undefined &&
                          listRoute.map((service) => {
                            return (
                              <>
                                <div className="col-md-4 ">
                                  < div className='upper-one bg-color-blackest mt-5 radius   '>
                                  <LazyLoad><img src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad>
                                    <h3 className='card-head-two'> {service?.title}</h3>

                                    {service?.para && <p>{service?.para}</p>}
                                    {service?.para1 && <p>{service?.para1}</p>}
                                    {service?.para2 && <p>{service?.para2}</p>}
                                  </div>
                                </div>
                              </>
                            )
                          })}
                      </div>
                    </div>

                    {/* service extra cards   */}
                    <div className='row mt-5'>
                      {listcard !== undefined &&
                        listcard.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'>
                              {i?.title}
                            </h2>
                            {i.para && <p className="card-text">{i.para}</p>}
                            {i.para1 && <p className="card-text">{i.para1}</p>}
                            {i.para2 && <p className="card-text">{i.para2}</p>}
                          </>
                        ))}
                      <div className="row upper">
                        {listcarddetails !== undefined &&
                          listcarddetails.map((service) => {
                            return (

                              <div className="col-md-4 ">
                                < div className='upper-one  bg-color-blackest mt-5 radius'>
                                <LazyLoad><img src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad>
                                  <h3> {service?.title}</h3>
                                  {/* <p>{service?.para}</p>
                                <p>{service?.para1}</p>
                                <p>{service?.para2}</p> */}
                                  {service?.para && <p>{service?.para}</p>}
                                  {service?.para1 && <p>{service?.para1}</p>}
                                  {service?.para2 && <p>{service?.para2}</p>}

                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>

                    {/* for points extra */}
                    <div className="row extra-points">
                      {listCertificate !== undefined &&
                        listCertificate.map((i) => (
                          <>
                            <h2 key={i.id} className='extra-heading'> {i?.title}</h2>
                            <p key={i.id} className='extra-text'> {i?.para}</p>
                            <p key={i.id} className='extra-text'> {i?.para1}</p>

                          </>
                        ))}


                      {listadvantage !== undefined &&
                        listadvantage.map((service) => {
                          return (
                            < div className='col-md-12 ' >
                              <p className='extra-text2'><i class="fa-solid fa-angle-right arrow-head "></i>
                                &nbsp;<span>{service?.para}</span></p>
                            </div>
                          )
                        })}


                    </div>

                    {/* for extra slugs */}
                    <div className='row images-and-heading'>
                      {listImages !== undefined &&
                        listImages.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'>
                              {i?.title}
                            </h2>
                            {i.para && <p className="card-text">{i.para}</p>}
                            {i.para1 && <p className="card-text">{i.para1}</p>}
                          </>
                        ))}
                      <div className="row upper">
                        {listImagesDetails !== undefined &&
                          listImagesDetails.map((service) => {
                            return (
                              <>
                                <div className="col-md-4 ">
                                  < div className='upper-one bg-color-blackest mt-5 radius   '>
                                  <LazyLoad> <img src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad>
                                    <h3 > {service?.title}</h3>
                                    <p>{service?.para}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                      </div>
                    </div>

                    {/* service-fifth */}
                    <div className="row">
                      {listWhy !== undefined &&
                        listWhy.map((i) => (
                          <>
                            <h2 key={i.id} className='heading-fifth'> {i?.title}</h2>
                            {/* <p key={i.id} className='fifth-para'> {i?.para}</p>
                            <p key={i.id} className='fifth-para'> {i?.para1}</p>
                            <p key={i.id} className='fifth-para'> {i?.para2}</p> */}
                            {i.para && <p className="fifth-para">{i.para}</p>}
                            {i.para1 && <p className="fifth-para">{i.para1}</p>}
                            {i.para2 && <p className="fifth-para">{i.para2}</p>}

                          </>
                        ))}


                      {listTools !== undefined &&
                        listTools.map((service) => {
                          return (
                            < div className='col-md-4 mt-3' >

                              <h3 className='heading-fifth2'>
                                {/* <i className="fa fa-check-circle tick-check" ></i>&nbsp; &nbsp; */}
                                <LazyLoad><img src={tick} alt='tick-icon' style={{ width: '21px', height: '21px', marginTop: '2px', marginRight: '2px' }} /></LazyLoad> &nbsp;
                                {/* <span className='icon-service'><FiCheck /></span> */}

                                <span> {service?.title}</span>
                              </h3>

                              {/* <p>{service?.para}</p>
                              <p>{service?.para1}</p>
                              <p>{service?.para2}</p>
                              <p>{service?.para3}</p>
                              <p>{service?.para4}</p>
                              <p>{service?.para5}</p> */}
                              {service?.para && <p className='fifth-para2'>{service.para}</p>}
                              {service?.para1 && <p className='fifth-para2'>{service.para1}</p>}
                              {service?.para2 && <p className='fifth-para2'>{service.para2}</p>}
                              {service?.para3 && <p className='fifth-para2'>{service.para3}</p>}
                              {service?.para4 && <p className='fifth-para2'>{service.para4}</p>}
                              {service?.para5 && <p className='fifth-para2'>{service.para5}</p>}
                              {service?.para6 && <p className='fifth-para2'>{service.para6}</p>}

                            </div>
                          )
                        })}


                    </div>
                    {/* service-six */}
                    <div className='row'>

                      {listCourse !== undefined &&
                        listCourse.map((i) => (
                          <>
                            <h2 key={i.id} className='heading-fifth'> {i?.title}</h2>
                            {i.para && <p className="fifth-para">{i.para}</p>}
                            {i.para1 && <p className="fifth-para">{i.para1}</p>}
                            {i.para2 && <p className="fifth-para">{i.para2}</p>}

                          </>
                        ))}
                      {listBenefit !== undefined &&
                        listBenefit.map((service) => {
                          return (
                            < div className='col-md-4 mt-0' >

                              <h3 className='heading-fifth2 mt-2'>
                                {/* <i className="fa fa-check-circle tick-check" ></i>&nbsp; &nbsp; */}
                                <LazyLoad><img src={tick} alt='tick-icon' style={{ width: '21px', height: '21px', marginTop: '2px', marginRight: '2px' }} /></LazyLoad>&nbsp;
                                <span> {service?.title}</span>
                              </h3>
                              {service?.para && <p className='fifth-para2'>{service.para}</p>}
                              {service?.para1 && <p className='fifth-para2'>{service.para1}</p>}
                              {service?.para2 && <p className='fifth-para2'>{service.para2}</p>}
                              {service?.para3 && <p className='fifth-para2'>{service.para3}</p>}
                              {service?.para4 && <p className='fifth-para2'>{service.para4}</p>}
                              {service?.para5 && <p className='fifth-para2'>{service.para5}</p>}
                              {service?.para6 && <p className='fifth-para2'>{service.para6}</p>}

                            </div>
                          )
                        })}

                    </div>
                    {/* service-listnftExtra*/}
                    <div className='row mt-5'>

                      {listnftExtra !== undefined &&
                        listnftExtra.map((i) => (
                          <>
                            <h2 key={i.id} className='heading-fifth'> {i?.title}</h2>
                            {i.para && <p className="fifth-para">{i.para}</p>}
                            {i.para1 && <p className="fifth-para">{i.para1}</p>}
                            {i.para2 && <p className="fifth-para">{i.para2}</p>}
                          </>
                        ))}
                      {listnftExtraDetails !== undefined &&
                        listnftExtraDetails.map((service) => {
                          return (
                            < div className='col-md-4 mt-0' >

                              <h3 className='heading-fifth2 mt-2'>
                                {/* <i className="fa fa-check-circle tick-check" ></i>&nbsp; &nbsp; */}
                                <LazyLoad><img src={tick} alt='tick-icon' style={{ width: '21px', height: '21px', marginTop: '2px', marginRight: '2px' }} /></LazyLoad> &nbsp;
                                <span> {service?.title}</span>
                              </h3>
                              {service?.para && <p className='fifth-para2'>{service.para}</p>}
                              {service?.para1 && <p className='fifth-para2'>{service.para1}</p>}
                              {service?.para2 && <p className='fifth-para2'>{service.para2}</p>}
                              {service?.para3 && <p className='fifth-para2'>{service.para3}</p>}
                              {service?.para4 && <p className='fifth-para2'>{service.para4}</p>}
                              {service?.para5 && <p className='fifth-para2'>{service.para5}</p>}
                            </div>
                          )
                        })}

                    </div>
                    {/* hiring */}

                    <div className="row">
                      {listHiring !== undefined &&
                        listHiring.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'> {i?.title}</h2>
                            <p key={i.id} className='card-text'> {i?.para}</p>

                          </>
                        ))}
                      {listHiringDetails !== undefined &&
                        listHiringDetails.map((process) => (
                          <div className="col-md-4" key={process.id}>
                            <h3 className='hiring-head '> {process.head}</h3>
                            <LazyLoad><img src={process?.imageTwo} className="hiring-img" alt={process.head} /></LazyLoad> 
                            
                            <p className='hiring-text mt-3'> {process.title}</p>
                            <p className='hiring-text'> {process.title1}</p>
                            <p className='hiring-text'> {process.title2}</p>
                            <p className='hiring-text'> {process.title3}</p>
                            <p className='hiring-text'> {process.title4}</p>
                            <p className='hiring-text'> {process.title5}</p>

                          </div>
                        ))}
                    </div>
                    {/* coclusion */}
                    {/* <div className=" service-two">
                      {listConclusion !== undefined &&
                        listConclusion.map((i) => (
                          <div key={i.id}>
                            <h2 className="service-heading-two ">
                              {i.title}
                            </h2>
                            <p className="para-two">
                              {i.para}
                            </p>

                          </div>
                        ))}

                    </div> */}
                  </div>
                </>
              )
            )}
          </div> {/* row */}
        </div>  {/* container */}
        <CalltoActionFive />
      </div>

      <FooterTwo />
      <Copyright />
    </>
  )
}

export default SCD