import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const SectionTitle = ({
  subtitle,
  title,
  title1,
  title2,
  title3,

  description,
  textAlign,
  radiusRounded
}) => {
  // const combinedTitle = `${title1}<br />${title2}`;
  const combinedTitle = `${title1 ? title1 : ''}${title2 ? '<br />' + title2 : ''}`;
  return (
  //   <div className={`section-title ${textAlign}`}>
  //     {subtitle && (
  //      <>

  //       <ScrollAnimation
  //         animateIn='fadeInUp'
  //         animateOut='fadeInOut'
  //         animateOnce={true}
  //       >
  //         <h2 className={`subtitle ${radiusRounded}`} style={{fontWeight:'bold'}}>
  //           <span
  //             className='theme-gradient'
  //             dangerouslySetInnerHTML={{ __html: subtitle }}
  //           />
  //         </h2>
  //       </ScrollAnimation>
  //       </> 
  //     )}
  //  <ScrollAnimation
  //       animateIn='fadeInUp'
  //       animateOut='fadeInOut'
  //       animateOnce={true}
  //     >
  //       <h1
  //         className='title titleSpecial'
  //         dangerouslySetInnerHTML={{ __html: combinedTitle }}
  //       ></h1>
  //     </ScrollAnimation> 
  //   <ScrollAnimation
  //       animateIn='fadeInUp'
  //       animateOut='fadeInOut'
  //       animateOnce={true}
  //     >
  //       <h1
  //         className='title'
  //         dangerouslySetInnerHTML={{ __html: title2 }}
  //       ></h1>
  //     </ScrollAnimation>  
  // <ScrollAnimation
  //       animateIn='fadeInUp'
  //       animateOut='fadeInOut'
  //       animateOnce={true}
  //     >
  //       <h1
  //         className='title'
  //         dangerouslySetInnerHTML={{ __html: title3 }}
  //       ></h1>
  //     </ScrollAnimation>   
  //     <ScrollAnimation
  //       animateIn='fadeInUp'
  //       animateOut='fadeInOut'
  //       animateOnce={true}
  //     >
  //       <h2
  //         className='title'
  //         dangerouslySetInnerHTML={{ __html: title }}
  //       ></h2>
  //     </ScrollAnimation>

  //     <ScrollAnimation
  //       animateIn='fadeInUp'
  //       animateOut='fadeInOut'
  //       animateOnce={true}
  //     >
  //       <p
  //         className='description b1 text-center'
  //         dangerouslySetInnerHTML={{ __html: description }}
  //       ></p>
  //     </ScrollAnimation>
  //   </div>
//   <div className={`section-title ${textAlign}`}>
//   {subtitle && (
//     <>
//       <ScrollAnimation
//         animateIn='fadeInUp'
//         animateOut='fadeInOut'
//         animateOnce={true}
//       >
//         <h2 className={`subtitle ${radiusRounded}`} style={{fontWeight:'bold'}}>
//           <span
//             className='theme-gradient'
//             dangerouslySetInnerHTML={{ __html: subtitle }}
//           />
//         </h2>
//       </ScrollAnimation>
//     </>
//   )}
//   {title1 && title2 && (
//     <ScrollAnimation
//       animateIn='fadeInUp'
//       animateOut='fadeInOut'
//       animateOnce={true}
//     >
//       <h1
//         className='title titleSpecial'
//         dangerouslySetInnerHTML={{ __html: combinedTitle }}
//       ></h1>
//     </ScrollAnimation>
//   )}
//   {title && (
//     <ScrollAnimation
//       animateIn='fadeInUp'
//       animateOut='fadeInOut'
//       animateOnce={true}
//     >
//       <h2
//         className='title'
//         dangerouslySetInnerHTML={{ __html: title }}
//       ></h2>
//     </ScrollAnimation>
//   )}
 
//     <ScrollAnimation
//       animateIn='fadeInUp'
//       animateOut='fadeInOut'
//       animateOnce={true}
//     >
//       <p
//         className='description b1 text-center'
//         dangerouslySetInnerHTML={{ __html: description }}
//       ></p>
//     </ScrollAnimation>

// </div>
<div className={`section-title ${textAlign}`}>
{subtitle && (
  <ScrollAnimation
    animateIn='fadeInUp'
    animateOut='fadeInOut'
    animateOnce={true}
  >
    <h2 className={`subtitle ${radiusRounded}`} style={{ fontWeight: 'bold' }}>
      <span
        className='theme-gradient'
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    </h2>
  </ScrollAnimation>
)}
{(title1 || title2 || title3) && (
  <ScrollAnimation
    animateIn='fadeInUp'
    animateOut='fadeInOut'
    animateOnce={true}
  >
    <h1
      className='title titleSpecial'
      dangerouslySetInnerHTML={{ __html: combinedTitle }}
    ></h1>
  </ScrollAnimation>
)}
{title && (
  <ScrollAnimation
    animateIn='fadeInUp'
    animateOut='fadeInOut'
    animateOnce={true}
  >
    <h2
      className='title'
      dangerouslySetInnerHTML={{ __html: title }}
    ></h2>
  </ScrollAnimation>
)}
 <ScrollAnimation
        animateIn='fadeInUp'
        animateOut='fadeInOut'
        animateOnce={true}
      >
        <p
          className='description b1 text-center'
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </ScrollAnimation>
</div>

  );
};
export default SectionTitle;
