import React from 'react';
import { AiOutlineYoutube } from 'react-icons/ai';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { BsPinterest } from 'react-icons/bs';
import TwitterIcon from '../../assets/icons/TwitterIcon';
import Logo from '../../assets/images/logo/locations.webp';
import footerOne from '../../data/footer/footerOne.json';
import CalltoActionSeven from '../../elements/calltoaction/CalltoActionSeven';
import ScrollTop from './ScrollTop';
import {Link} from "react-router-dom";
import LazyLoad from 'react-lazyload';
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];

const indexOneLink = footerIntemOne.quicklink;
const indexTwoLink = footerIntemTwo.quicklink;

const FooterTwo = () => {
  const handleClick = (name, website) => {
    // document.location();
    // const process = window.confirm(`You are leaving towards ${name}.com`);
    // if (process) {
    window.open(website);
    // }
  };
  return (
    <>
      <footer className='rn-footer footer-style-default variation-two'>
      
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 p-4'>
                <div className='text-center'>
                <LazyLoad>
                  <img src={Logo} alt='Logo'  style={{ width: '100%', height: 'auto' }}/>
            </LazyLoad>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <div>
                  <div className='inner'>
                    <CalltoActionSeven />
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
              {/* Start Single Widget  */}
              <div className='col-lg-2 col-md-6 col-sm-6 col-12 titl2'>
                <div className='rn-footer-widget'>
                  <h4 className='title'>{footerIntemOne.title}</h4>
                  <div className='inner pt-3'>
                    <ul className='footer-link link-hover'>
                      {indexOneLink.map((data, index) => (
                      
                         <li key={index}><Link to={`${data.url}`}> <span>{data.text}</span></Link></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
       
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className='col-lg-3 col-md-6 col-sm-6 col-12 titl2'>
                <div className='rn-footer-widget'>
                  <div className='widget-menu-top'>
                    <h4 className='title '>{footerIntemTwo.title}</h4>
                    <div className='inner pt-3'>
                      <ul className='footer-link link-hover'>
                        {indexTwoLink.map((data, index) => (
                          // <li key={index}>
                          //   <span>{data.text}</span>
                          // </li>
                          <li key={index}><Link to={`${data.url}`}> <span>{data.text}</span></Link></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='rn-footer-widget'>
                  <h4 className='title'> Follow Us</h4>
                  <div className='inner pt-3'>
                    <ul className=' social-default2  social-icons'>
                      <li className='cursor-pointer' title='Facebook'>
                        <span
                          onClick={() =>
                            handleClick(
                              'facebook',
                              'https://www.facebook.com/zaisystems'
                            )
                          }
                        >
                          <FiFacebook />
                        </span>
                      </li>
                      <li className='cursor-pointer' title='Instagram'>
                        <span
                          onClick={() =>
                            handleClick(
                              'Instagram',
                              'https://instagram.com/zaisystems?utm_medium=copy_link'
                            )
                          }
                        >
                          <FiInstagram />
                        </span>
                      </li>
                      <li className='cursor-pointer' title='LinkedIn'>
                        <span
                          onClick={() =>
                            handleClick(
                              'linkedin',
                              'https://www.linkedin.com/company/zai-systems-smc-private-limited/?viewAsMember=true'
                            )
                          }
                        >
                          <FiLinkedin />
                        </span>
                      </li>
                    </ul>
                    <ul
                      className='social-default2  social-icons'
                      style={{ marginTop: '1rem' }}
                    >
                      <li className='cursor-pointer' title='Youtube'>
                        <span
                          onClick={() =>
                            handleClick(
                              'youtube',
                              'https://youtube.com/@Zai-Systems?si=usewzFAweWxal_28'
                            )
                          }
                        >
                          <AiOutlineYoutube />
                        </span>
                      </li>
                      <li className='cursor-pointer' title='twitter'>
                        <span
                          onClick={() =>
                            handleClick(
                              'twitter',
                              'https://twitter.com/ZaiSystems?t=_lwGP4YNVmjcQlbQfnMZuQ&s=08'
                            )
                          }
                        >
                          <TwitterIcon />
                        </span>
                      </li>
                      <li className='cursor-pointer' title='pinterest'>
                        <span
                          onClick={() =>
                            handleClick(
                              'pinterest',
                              'https://www.pinterest.com/zaisystems/'
                            )
                          }
                        >
                          <BsPinterest />
                        </span>
                      </li>
                    </ul>

                    <h4 className='title pt-5'>Working Hours</h4>
                    <p className='timing'>
                      Monday-Friday <br /> 11:00am to 8:00pm
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterTwo;
