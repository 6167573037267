import { useRef, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import LogoTwo from '../../assets/images/logo/logo-dark.webp';
import LogoOne from '../../assets/images/logo/logo.webp';
import Logo from '../../elements/logo/Logo';
import Darkmode from './Darkmode';
import MobileMenu from './MobileMenu';
import Nav from './Nav';
import useStickyHeader from './useStickyHeader';

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? 'sticky' : ''}`;
  // const { clientHeight } = ref;

  // const checkChange = (value) => {
  //   setCheck(value);
  // };
  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className='container position-relative'>
          <div className='row align-items-center row--0'>
            <div className='col-lg-1 col-md-2 col-3'>
              <Logo image={LogoOne} image2={LogoTwo} style={{ width: 'auto', height: 'auto' }}/>
            </div>
            <div className='col-lg-11 col-md-10 col-9 position-static'>
              <div className='header-right'>
                <nav className='mainmenu-nav d-none d-lg-block'>
                  <Nav />
                </nav>

                <div className='mobile-menu-bar ml--5 d-block d-lg-none'>
                  <div className='hamberger'>
                    <span
                      className='hamberger-button'
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                <Darkmode />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
