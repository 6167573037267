import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoad from 'react-lazyload';
import Image1 from '../../assets/images/timeline/timeline-01.webp';
import Image2 from '../../assets/images/timeline/timeline-02.webp';

const TimelineData = [
  {
    id: '1',
    date: 'Step-1',
    title: 'MISSION STATEMENT',
    description:
      "We help businesses all around the world succeed using various IT services. We think of technology as a tool that should make things easier, not harder. That's why we're committed to providing excellent software development and solutions that are customized to fit each client's specific needs.",
    image: Image1
  },
  {
    id: '2',
    date: 'Step-2',
    title: 'OUR VISION',
    description:
      "We're focused on helping businesses use technology to reach their goals, make their work easier, and stay competitive in a constantly changing market. We also offer lots of training courses designed to teach people the latest IT skills. We want them to do great in their careers and be part of the ever-growing world of technology.",
    image: Image2
  }
];

const TimelineOne = () => {
  return (
    <div className='rn-timeline-wrapper timeline-style-one position-relative'>
      <div className='timeline-line'></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className='single-timeline mt--15' key={index}>
          <div className='timeline-dot'>
            <div className='time-line-circle'></div>
          </div>
          <div className='single-content'>
            <div className='inner'>
              <div className='row row--30 align-items-center'>
                <div className='order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--10'>
                  <div className='content'>
                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <span className='date-of-timeline'>{data.date}</span>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <h3 className='title' style={{fontSize:'25px'}}>{data.title}</h3>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <p className='description' style={{textAlign:'justify'}}>{data.description}</p>
                    </ScrollAnimation>

                    <div className='read-morebtn'>
                      <ScrollAnimation
                        animateIn='fadeInUp'
                        animateOut='fadeInOut'
                        animateOnce={true}
                      >
                        <a
                          className='btn-default btn-medium round'
                          href='/contact'
                        >
                          <span>Get Started Now</span>
                        </a>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className='order-1 order-lg-2 col-lg-6'>
                  <div className='thumbnail'>
                    <LazyLoad><img
                        className='w-100'
                        src={data.image}
                        alt={data.title}
                      />
                    </LazyLoad>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineOne;
