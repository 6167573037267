import React from 'react'
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import BlogsOne from './BlogsOne';

import SectionTitle from '../sectionTitle/SectionTitle';

const BlogMain = () => {
    return (
        <>
            <SEO>
                <title>ZAI SYSTEMS | Blogs</title>
                <meta
                    name='description'
                    content="Stay ahead in the tech world with expert analysis, industry updates, and thought-provoking Zai Systems blogs"
                />
                <meta
                    name='robots'
                    content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
                />
                <link rel='canonical' href='https://www.zaisystems.com/blogs' />
            </SEO>
            <Layout>
                <BreadcrumbOne
                    title='Blogs'
                    rootUrl='/'
                    parentUrl='Home'
                    currentUrl='Blogs'
                />
                {/* <div className='main-content'>
                    <div className='rwt-team-area rn-section-gap'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 mb--15'>
                                    <SectionTitle
                                        textAlign='text-center'
                                        radiusRounded=''
                                        subtitle='Our Experts'
                                        title="Team Style Three"
                                        description="We have put together a team of qualified professionals and specialists to offer complete IT services and solutions in accordance with each client's specific needs."
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </div> */}
                {/* <BlogsOne
                    serviceStyle='service__style--1 bg-color-blackest radius mt--25'
                    textAlign='text-center'
                /> */}

                <div className='rn-service-area rn-section-gap'>
                    <div className='container'>
                        {/* <div className='row'>
                            <div className='col-lg-12'>
                                <SectionTitle
                                    textAlign='text-center'
                                    radiusRounded=''
                                    subtitle='Services'
                                    title='A Sneak Peek at Our Services'
                                    description='We are a full-service IT company that provides customized solutions for your business needs. Whether you need software development, web design, cloud computing, or IT consulting, we have the expertise and experience to deliver high-quality results.'
                                />
                            </div>
                        </div> */}
                        <BlogsOne
                            serviceStyle='service__style--1 bg-color-blackest radius mt--2'
                            textAlign='text-center'
                        />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default BlogMain