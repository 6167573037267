import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom'

const ServiceList = [
  {
    icon: '1',
    title: 'Discussion and Implications',
    description:
      'We discuss the requirements of our clients, and clearly set expectations beforehand.'
  },
  {
    icon: '2',
    title: 'Concepts and Initiatives ',
    description:'Our IT experts come up with all kinds of creative ideas and initiatives to deliver the best results.'
  },
  {
    icon: '3',
    title: 'Testing and Trying',
    description:'After agreeing on the ideas and plans, we conduct meetings as scheduled and provide feedback.'
  },
  {
    icon: '4',
    title: 'Execution and Installation',
    description:'Once the final plan is approved, everything will be carried out according to the agreed contract.'
  }
];

const ServiceFour = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-3 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='icon'>
                <div className='line'></div>
                {val.icon}
              </div>
              <div className='content'>
                <h4 className='title'>
                  <Link
                    to='#service'
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className='description b1 color-gray mb--0'
                  dangerouslySetInnerHTML={{ __html: val.description }}
                  style={{textAlign:'center'}}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFour;
