import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey='0'
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='0'>
            What is your experience?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            We have more than 4 years of experience. Our company has worked with
            different multidimensional local and international companies
            including retails, technology, telecommunication companies and many
            more. Our clients were satisfied with our best services and customer
            support.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='1'>
            What technology do you use?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='1'>
          <Card.Body>
            We use different technology for our services. For back-end projects
            we use Python, Django and Node.js. and for front-end projects, we
            use Angular.js, and React.js.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='2'>
            What are the benefits of Managed Services?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='2'>
          <Card.Body>
            One of the biggest advantages of managed IT services is cost savings
            that businesses experience by making business IT support costs more
            predictable and manageable. A managed IT service approach also
            provides your businesses with comprehensive security, customized
            backup and recovery solutions, and state-of-the-art cloud storage
            and hosting.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='3'>
            Does The Zai Systems provide afterhours emergency support?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='3'>
          <Card.Body>Yes, our customer support is available 24x7</Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='4'>
            What differentiates The Zai Systems? Why Should I Choose You?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='4'>
          <Card.Body>
            Zai Systems is a reliable IT partner with a strong focus on service.
            We pride ourselves on having the ability to understand your business
            and your customers’ needs so that we can customize our service to
            you. Here we ensure you provide that same commitment to your
            customers. We as a dedicated partner always willing to provide
            predictability of service, cost certainty, and responsiveness. Our
            organizational expertise, extensive senior talent, professional
            knowledge, and effective software systems will help you achieve your
            business goals with us.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
