import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import BlogList from '../components/blog/itemProp/BlogList';
import { BackendImages, courseImg } from '../data/Courses';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';



const InternationalConsulting = () => {
  return (
    <>
      <SEO>
        <title>Best IT Courses in Pakistan by ZAI SYSTEMS</title>
        <meta
          name='description'
          content="Explore your favorite courses in Pakistan's best IT training institute, including WordPress, App Development, React JS, ASP. Net and many others."
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/courses' />
      </SEO>
      <main className='page-wrapper'>
        <HeaderOne
          btnStyle='btn-small btn-icon'
          HeaderSTyle='header-transparent'
        />

        {/* Start Slider Area  */}
        <div
          className='slider-area slider-style-1 variation-default height-550 bg_image'
          data-black-overlay='5'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/all-courses.webp)`
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <h1 className='title display-four'>
                    Jumpstart your Career with Our<br/>
                    <span className='theme-gradient mt-2'>Online IT Courses</span>
                  </h1>
                  <p className='description text-center text-white' >
                    Zai Systems brought an amazing opportunity for all students
                    and professionals who want to master skills online. We offer
                    certified IT training courses online, including online
                    WordPress course, online course for graphic designing, web
                    and app development course, and much more. Willing to learn?
                    Register your seat now in our IT courses and get certified
                    in your good to go skill.
                  </p>
                  <div className='button-group'>
                    <Link
                      className='btn-default btn-medium btn-border btn-icon'
                      to='/contact'
                    >
                      Contact Us
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <div className='main-content'>
          <div className='rwt-portfolio-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Courses'
                    title='Research the topic extensively!'
                    description=''
                  />
                </div>
              </div>
              <PortfolioOne Column='col-lg-4 col-md-6 mt--30 portfolio' />
            </div>
          </div>
        </div>

        <Separator />

        <Separator />
        <div className='blog-area rn-section-gap padding_small'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='our Courses'
                  title='Full Stack Web Development'
                  description=''
                />
              </div>
            </div>
            <div className='row row--15 mt--0'>
            <h3 className='p-3'>Front-End Languages</h3>

              {courseImg.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-4 col-md-6 col-sm-12 col-12 mt--20'
                >
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
            <div className='row mt-0'>
              <h3 className='p-3'>Backend Languages</h3>
              {BackendImages.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-4 col-md-6 col-sm-12 col-12 mt--20'
                >
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Start Elements Area  */}
        <div className='rwt-callto-action-area rn-section-gapBottom'>
          <div className='wrapper'>
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default InternationalConsulting;
