import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import LazyLoad from "react-lazyload";
import Service1 from "../../assets/images/service/drive.webp"
import Service2 from "../../assets/images/service/zai web team vector-01.webp"
import Service3 from "../../assets/images/service/customer.webp"

const ServiceList = [
  {
    image: Service1,
    title: "Drive To Inspire",
    width:'50%',
    description:
      "We work hard to make every day count. We recognize that the route to success lies is in the small detail.",
  },
  {
    image: Service2,
    title: "Team Work",
    width:'50%',
    description:
      "Out collective culture of resilience and perseverance is a key attribute to overcome all challenges.",
  },
  {
    image: Service3,
    title: "Customer Focus",
    width:'50%',
    description:
      "“Customer is King” is not just a catch phrase for us, it is our way of life, our clients are witness to it.",
  },
];
const ServiceThree = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <LazyLoad><img src={`${val.image}`} alt={val.title} style={{ width: val.width }}/>
                  </LazyLoad>
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                    style={{textAlign:'center'}}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThree;
