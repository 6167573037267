import React from 'react'
import Lazyload from 'react-lazyload';
// import { Link } from 'react-router-dom';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';
import HeaderOne from '../../common/header/HeaderOne';
import CalltoActionFive from '../calltoaction/CalltoActionFive';
import { BlogsData } from '../../elements/Blogs/BlogsData';
import SEO from '../../common/SEO';
import { useParams } from 'react-router-dom'

const BLOGS = () => {
    const { blog } = useParams()
    const newBlog = BlogsData.filter((v) => v.blog === blog);
    console.log();
    return (
        <>
          
            <main className='page-wrapper'>

                <HeaderOne
                    btnStyle='btn-small btn-icon'
                    HeaderSTyle='header-transparent'
                />
                <div className='rwt-portfolio-details rn-section-gap_blogs '>
                    <div className='container mb-3'>
                        <div className='row'>
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='inner'>
                                    <div className='details-list'>
                                        <div className='row mt--0 row--30'>
                                            <div className='col-lg-12 mt_md--30 mt_sm--30'>
                                                {newBlog.map(
                                                    ({
                                                        para,
                                                        list,
                                                        listTools,
                                                        listCourse,
                                                        listWhy,
                                                        listCertificate,
                                                        listAud,
                                                        listOne,
                                                        listTemp,
                                                        listService,
                                                        listRoute,
                                                        listBenefit,
                                                        listSeo
                                                    }) => (
                                                        <>
                                                        <SEO>
                    {listSeo.map(({ id, title, content, link }) => (
                      <React.Fragment key={id}>
                        <title>{title}</title>
                        <meta name='description' content={content} />
                        <meta name='robots' content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' />
                        <link rel='canonical' href={link} />
                      </React.Fragment>
                    ))}
                  </SEO>
                                                        <div className='inner-container'>
                                                            {para !== undefined &&
                                                                para.map(({ title, para, para1, largeImage }) => (
                                                                    <>
                                                                        <h1 className='course-heading-top text-center'> {title} </h1>

                                                                        <div className='thumbnail alignwide text-center pt-1 pb-5'>
                                                                            <Lazyload><img
                                                                                    className='radius'
                                                                                    src={largeImage}
                                                                                    // eslint-disable-next-line no-useless-concat
                                                                                    alt={title}
                                                                                />
                                                                            </Lazyload>
                                                                        </div>
                                                                        <p className=''>{para}</p>
                                                                        <p className=''>{para1}</p>
                                                                    </>
                                                                ))}

                                                            {/* {listOne !== undefined &&
                                                                        listOne.map(({ para, head }) => (
                                                                            <>
                                                                                {head && (
                                                                                    <h2 className='course-heading-down mb-3' style={{ fontSize: '24px' }}>{head}</h2>
                                                                                )}
                                                                                <div className='evs-listgroup'>
                                                                                    <p>{para} </p>
                                                                                </div>
                                                                            </>
                                                                        ))} */}

                                                            {list?.map(({ paragraph, head, para }) => (
                                                                <>
                                                                    {head && (
                                                                        <h2 className='course-heading-down mb-3' style={{ fontSize: '24px' }}>{head}</h2>
                                                                    )}
                                                                    {para ? (
                                                                        <div className='evs-listgroup'>
                                                                            <p>{para} </p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='evs-listgroup'>
                                                                            <p>{paragraph} </p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ))}
                                                            {listTools !== undefined &&
                                                                listTools.map(({ head, para }) => (
                                                                    <>
                                                                        {head && (
                                                                            <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                                {head}
                                                                            </h2>
                                                                        )}

                                                                        <div className='evs-listgroup'>
                                                                            <p>{para} </p>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            {listCourse !== undefined &&
                                                                listCourse.map(({ para, head }) => (
                                                                    <>
                                                                        {head && (
                                                                            <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                                {head}
                                                                            </h2>
                                                                        )}
                                                                        <p>{para}</p>
                                                                    </>
                                                                ))}
                                                            {listWhy !== undefined &&
                                                                listWhy.map(({ para, head }) => (
                                                                    <>
                                                                        {head && (
                                                                            <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                                {head}
                                                                            </h2>
                                                                        )}
                                                                        <p>{para}</p>
                                                                    </>
                                                                ))}
                                                            {listCertificate !== undefined &&
                                                                listCertificate.map(({ para, head }) => (
                                                                    <>
                                                                        {head && (
                                                                            <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                                {head}
                                                                            </h2>
                                                                        )}
                                                                        <div className='evs-listgroup'>
                                                                            <p>{para} </p>
                                                                        </div>
                                                                    </>
                                                                ))}


                                                            {listAud?.map(({ paragraph, para, head }) => (
                                                                <>
                                                                    {head && (
                                                                        <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>{head}</h2>
                                                                    )}
                                                                    {paragraph ? (
                                                                        <div className='evs-listgroup'>
                                                                            <p>{paragraph} </p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='evs-listgroup'>
                                                                            <p>{para} </p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ))}

                                                            {/* {listTemp !== undefined &&
                                                            listTemp?.map(({ para, head }) => (
                                                                <>
                                                                    {head && (
                                                                        <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                            {head}
                                                                        </h2>
                                                                    )}
                                                                    <div className='evs-listgroup'>
                                                                        <p>{para} </p>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        {listService !== undefined &&
                                                            listService.map(({ head, para }) => (
                                                                <>
                                                                    {head && (
                                                                        <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                            {head}
                                                                        </h2>
                                                                    )}
                                                                    <div className='evs-listgroup'>
                                                                        <p>{para} </p>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        {listRoute !== undefined &&
                                                            listRoute.map(({ head, para }) => (
                                                                <>
                                                                    {head && (
                                                                        <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                            {head}
                                                                        </h2>
                                                                    )}
                                                                    <div className='evs-listgroup'>
                                                                        <p>{para} </p>
                                                                    </div>
                                                                </>
                                                            ))}

                                                        {listBenefit !== undefined &&
                                                            listBenefit.map(({ head, para }) => (
                                                                <>
                                                                    {head && (
                                                                        <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize: '24px' }}>
                                                                            {head}
                                                                        </h2>
                                                                    )}
                                                                    <div className='evs-listgroup'>
                                                                        <p>{para} </p>
                                                                    </div>
                                                                </>
                                                            ))} */}


                                                        </div >
                                                        </>
                                                    )
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <CalltoActionFive />
                </div>

                <FooterTwo />
                <Copyright />
            </main >
        </>
    )
}

export default BLOGS