import React from 'react';
import Lazyload from 'react-lazyload';
import { Link } from 'react-router-dom';
import { LanguageData } from '../../data/AllData';
const PortfolioDetailsContent = ({ data }) => {
  let { category } = data;
  const newList = LanguageData.filter((v) => v.category === category);

  return (
    <div className='rwt-portfolio-details rn-section-gap padding_small'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-10 offset-lg-1'>
            <div className='inner'>
              <div className='details-list'>
                <div className='row mt--0 row--30'>
                  <div className='col-lg-12 mt_md--30 mt_sm--30'>
                    {newList.map(
                      ({
                        para,
                        list,
                        listAud,
                        listCourse,
                        listCertificate,
                        listOne,
                        listTemp,
                        listService,
                        listRoute,
                        listTools,
                        listBenefit,
                        listWhy
                      }) => (
                        <div className='inner-container'>
                          <h1 className='course-heading-top'> {data.title} </h1>
                          <p>{para}</p>
                          <div className='thumbnail alignwide text-center pt-5 pb-5'>
                            <Lazyload><img
                                className='radius '
                                src={`${process.env.PUBLIC_URL}/${data.largeImage}`}
                                // eslint-disable-next-line no-useless-concat
                                alt={data.title}
                              />
                            </Lazyload>
                          </div>

                          {listOne !== undefined &&
                            listOne.map(({ para, head }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mb-3' style={{ fontSize:'24px' }}>{head}</h2>
                                )}
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}

                          {list?.map(({ paragraph, head, para }) => (
                            <>
                              {head && (
                                <h2 className='course-heading-down mb-3' style={{ fontSize:'24px' }}>{head}</h2>
                              )}
                              {para ? (
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              ) : (
                                <div className='evs-listgroup'>
                                  <p>{paragraph} </p>
                                </div>
                              )}
                            </>
                          ))}

                          {listAud?.map(({ paragraph, para, head }) => (
                            <>
                              {head && (
                                <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize:'24px' }}>{head}</h2>
                              )}
                              {paragraph ? (
                                <div className='evs-listgroup'>
                                  <p>{paragraph} </p>
                                </div>
                              ) : (
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              )}
                            </>
                          ))}

                          {listTemp !== undefined &&
                            listTemp?.map(({ para, head }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2'style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}
                          {listService !== undefined &&
                            listService.map(({ head, para }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2'style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}
                          {listRoute !== undefined &&
                            listRoute.map(({ head, para }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2'style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}
                          {listTools !== undefined &&
                            listTools.map(({ head, para }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}

                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}
                          {listBenefit !== undefined &&
                            listBenefit.map(({ head, para }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}

                          {listCourse !== undefined &&
                            listCourse.map(({ para, head }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <p>{para}</p>
                              </>
                            ))}
                          {listWhy !== undefined &&
                            listWhy.map(({ para, head }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <p>{para}</p>
                              </>
                            ))}
                          {listCertificate !== undefined &&
                            listCertificate.map(({ para, head }) => (
                              <>
                                {head && (
                                  <h2 className='course-heading-down mt-4 mb-2' style={{ fontSize:'24px' }}>
                                    {head}
                                  </h2>
                                )}
                                <div className='evs-listgroup'>
                                  <p>{para} </p>
                                </div>
                              </>
                            ))}
                        </div>
                      )
                    )}
                  </div>
                  <div className='col-lg-12'>
                    <div className='content-left'>
                      {/* <h4 className='title'>{data.title}</h4> */}
                      <div className='single-list-wrapper'>
                        <div className='single-list'>
                          <label>Certificate:</label>
                          <span>{data.date}</span>
                        </div>
                        <div className='single-list'>
                          <label>Duration:</label>
                          <span>{data.client}</span>
                        </div>
                        <div className='single-list'>
                          <label>Category:</label>
                          <span>{data.title}</span>
                        </div>
                        <div className='single-list'>
                          <label> Original Price:</label> 
                          <span style={{textDecoration: 'line-through'}}>24,000 PKR/Month</span>
                        </div>
                        <div className='single-list'>
                          <label> Limited-Time Discount:</label> 
                          <span> Save 6000 PKR/Month</span>
                        </div>
                        <div className='single-list'>
                          <label> New Price:</label> 
                          <span>18,000 PKR/Month</span>
                        </div>
                      </div>
                      <div className='view-btn mt--25 '>
                        <Link
                          className='btn-default btn-medium round'
                          to={`${data.btn.link}`}
                        >
                          {data.btn.buttontext}
                        </Link>

                        <a
                          href={`${data.btn.registrationLink}`}
                          className='btn-default btn-medium round'
                          target='_blank'
                          style={{ marginLeft: '1rem' }}
                          rel='noreferrer'
                        >
                          {data.btn.registration}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailsContent;
