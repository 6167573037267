import React from 'react';

const Copyright = () => {
  return (
    <div className='copyright-area copyright-style-one'>
      <div className='container'>
        <div className='row align-items-center'>
          {/* <div className="col-lg-6 col-md-8 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className='col-lg-12 col-md-12 col-sm-12 col-12 text-center'>
            <div className=' text-center  '>
              <span className=' text-center'>
                © 2024 – Zai Systems – All Rights Reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
