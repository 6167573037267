import React from 'react';
import { AiOutlineYoutube } from 'react-icons/ai';
import ceo from '../../assets/images/contactMailsIcons/ceo.webp';
import hr from '../../assets/images/contactMailsIcons/hr.webp';
import info from '../../assets/images/contactMailsIcons/info.webp';
import call from '../../assets/images/call.webp'
import {
  FiFacebook,
  // FiHeadphones,
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiMapPin
} from 'react-icons/fi';
import TwitterIcon from '../../assets/icons/TwitterIcon';
import ContactForm from './ContactForm';
import LazyLoad from "react-lazyload";

const ContactOne = ({ head }) => {
  const handleClick = (name, website) => {
    window.open(website);
  };
  return (
    <>
      <div className='row row--15 mt--40'>
        <div className='col-lg-12'>
          <div className='rn-contact-address mt_dec--30'>
            <div className='row justify-content-center'>
              {head ? (
                <div className='row mt--0 row--15 justify-content-center'>
                  <div className='col-lg-9'>
                    <ContactForm formStyle='contact-form-1' />
                  </div>
                </div>
              ) : (
                <>
                  <div className='col-lg-12 col-md-12 col-12  '>
                    <h3>Mails</h3>
                  </div>

                  <div className='col-lg-3 col-md-6 col-12 '>
                    <div className='rn-address' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className='icon'>
                        {/* <FiMail /> */}
                        <LazyLoad><img src={ceo} width={'48px'} height={'45px'} alt='CEO' /></LazyLoad>
                      </div>
                      <div className='inner pt-3'>
                        <h4 className='title text-center'>CEO </h4>
                        <p>
                          <a href='mailto:ceo@zaisystems.com' target="_blank" rel="noopener noreferrer nofollow">
                            ceo@zaisystems.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6 col-12'>
                    <div className='rn-address ' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className='icon'>
                      <LazyLoad> 
                        <img src={hr} width={'48px'} height={'45px'} alt='HR' />
                        </LazyLoad>
                        {/* <FiMail /> */}
                      </div>
                      <div className='inner pt-3'>
                        <h4 className='title text-center'>HR </h4>
                        <p>
                          <a href='mailto:hr@zaisystems.com' target="_blank" rel="noopener noreferrer nofollow">
                            hr@zaisystems.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6 col-12'>
                    <div className='rn-address ' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className='icon'>
                        {/*<LazyLoad><img src={email} width={'48px'} height={'45px'} alt='email' /></LazyLoad>  */}
                        <FiMail style={{ width: '48px', height: '45px' }} />
                      </div>
                      <div className='inner pt-3'>
                        <h4 className='title text-center'>Email Address</h4>
                        <p>
                          <a href='mailto:zaisystems@gmail.com' target="_blank" rel="noopener noreferrer nofollow">
                            zaisystems@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6 col-12'>
                    <div className='rn-address ' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className='icon'>
                      <LazyLoad> <img src={info} width={'45px'} height={'30px'} alt='info' /></LazyLoad>
                        {/* <FiMail /> */}
                      </div>
                      <div className='inner pt-3'>
                        <h4 className='title text-center'>Info</h4>
                        <p>
                          <a href='mailto:info@zaisystems.com' target="_blank" rel="noopener noreferrer nofollow">
                            info@zaisystems.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!head && (
        <>
          <div className='col-lg-12 col-md-12 col-12 mt-5'>
            <h3>Phone/Address</h3>
          </div>
          <div className='row row--15 mt--40  '>
            <div className='col-lg-12'>
              <div className='rn-contact-address mt_dec--30'>
                <div className='row'>
                  {/* <div className='col-lg-12 col-md-12 col-12 rn-address p-0  '> */}
                  <div className='col-lg-3 col-md-6 col-12  '>
                    <div className='rn-address justify-content-center'>
                      <div className='d-flex'>
                        {/* <div className='icon'>
                          <FiHeadphones />
                        </div> */}
                          <div className='icon mt-2'>
                          <LazyLoad> <img src={call} alt='Call' style={{ width: '30px',height:'30px' }} /></LazyLoad> 
                      {/* <FiHeadphones /> */}
                    </div>
                        <div className='inner pt-3'>
                          <h4 className='title'>Contact Number</h4>
                          <p>
                            <a href='tel:+92 334-6906960' target="_blank" rel="noopener noreferrer nofollow">+92 334 6906960</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-4  '>
                  <div className=' rn-address justify-content-center'>
                    <div className='d-flex '>
                      <div className='icon' style={{fontSize:'30px'}}>
                        <FiMapPin className='mt-2'/>
                      </div>
                      <div className='inner pt-3'>
                        <h4 className='title'>Our Location</h4>
                        <p>USA, Pakistan</p>
                      </div>
                    </div>
                 </div> 
                 </div>
                  <div className='col-lg-4 col-md-6 col-12 '>
                  <div className=' rn-address justify-content-center'>
                    
                    {/* <div className='rn-footer-widget'> */}
                      <h4 className='title '> Follow Us</h4>
                      <div className='inner '>
                        <ul className='social-icon social-default justify-content-start'>
                          <li className='cursor-pointer'>
                            <span
                              onClick={() =>
                                handleClick(
                                  'facebook',
                                  'https://www.facebook.com/zaisystems'
                                )
                              }
                            >
                              <FiFacebook />
                            </span>
                          </li>

                          <li className='cursor-pointer'>
                            <span
                              onClick={() =>
                                handleClick(
                                  'Instagram',
                                  'https://instagram.com/zaisystems?utm_medium=copy_link'
                                )
                              }
                            >
                              <FiInstagram />
                            </span>
                          </li>
                          <li className='cursor-pointer'>
                            <span
                              onClick={() =>
                                handleClick(
                                  'linkedin',
                                  'https://www.linkedin.com/company/zai-systems-smc-private-limited/?viewAsMember=true'
                                )
                              }
                            >
                              <FiLinkedin />
                            </span>
                          </li>
                          <li className='cursor-pointer'>
                            <span
                              onClick={() =>
                                handleClick(
                                  'linkedin',
                                  'https://youtube.com/@Zai-Systems?si=usewzFAweWxal_28'
                                )
                              }
                            >
                              <AiOutlineYoutube />
                            </span>
                          </li>
                          <li className='cursor-pointer'>
                            <span
                              onClick={() =>
                                handleClick(
                                  'linkedin',
                                  'https://twitter.com/ZaiSystems?t=_lwGP4YNVmjcQlbQfnMZuQ&s=08'
                                )
                              }
                            >
                              <TwitterIcon />
                            </span>
                          </li>
                        </ul>
                      </div>
                    {/* </div> */}
                 </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </>
      )}
    </>
  );
};
export default ContactOne;
