import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import icon2 from '../../assets/icons/attendence-01.webp';
import icon10 from '../../assets/icons/benefits and expenses-01.webp';
import icon11 from '../../assets/icons/custodies-01.webp';
import icon17 from '../../assets/icons/dashboard-01.webp';
import icon15 from '../../assets/icons/decisions-01.webp';
import icon4 from '../../assets/icons/employee self service-01.webp';
import icon16 from '../../assets/icons/enf service-01.webp';
import icon6 from '../../assets/icons/job portal-01.webp';
import icon3 from '../../assets/icons/leaves-01.webp';
import icon8 from '../../assets/icons/letters and forms-01.webp';
import icon9 from '../../assets/icons/loans-01.webp';
import icon14 from '../../assets/icons/memos-01.webp';
import icon12 from '../../assets/icons/notice and warnings-01.webp';
import icon1 from '../../assets/icons/payroll-01.webp';
import icon5 from '../../assets/icons/performance and evolution-01.webp';
import icon18 from '../../assets/icons/rules and regulations-01.webp';
import icon7 from '../../assets/icons/task management-01.webp';
import icon13 from '../../assets/icons/workflow and approvals-01.webp';
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const features = [
  {
    link: '/impulse-hcm-360/payroll-management',
    title: 'Payroll Management',
    description:
      `Streamline your payroll processes with ease. The issue, approve, and transfer salaries to
       bank accounts in just one click. Impulse HCM 360° automatically calculates deductions, and
        additions, and ensures accuracy.`,
    icon: icon1
  },
  {link: '/impulse-hcm-360/attendance-management',
    title: 'Attendance Tracking',
    description:
      `Effortlessly monitor attendance and fingerprint records while automating the calculation
       of working hours, additions, and deductions. Impulse HCM 360° simplifies workforce management,
        saving your time and effort.`,
    icon: icon2
  },
  {   link: `/impulse-hcm-360/customizable-leave-management`,
    title: `Customizable Leave Management`,
    description:
      `Create and customize leave types and effortlessly calculate leave balances according to your
       organization's policies. Bind specific leave types to specific contracts and define unpaid leaves seamlessly.`,
    icon: icon3
  },
  {    link: `/impulse-hcm-360/employee-self-service`,
    title: `Employee Self-Service`,
    description:
      `Empower your team with over 10 services and request options through Impulse HCM 360°'s intuitive self-service platform.
       Enhance employee satisfaction and streamline HR processes efficiently and effectively.`,
    icon: icon4
  },
  {    link: `/impulse-hcm-360/performance-evaluation`,
    title: `Performance Evaluation`,
    description:
      `Create evaluation forms, define competence criteria and weight, and assign them to employees based on job title
       or department. Impulse HCM 360° facilitates the review process, ensuring performance evaluations.`,
    icon: icon5
  },
  {link:"/impulse-hcm-360/job-portal",
    title: `Job Portal`,
    description:
     `Attract top talent by creating unlimited job posts and sharing your company's private URL with applicants.
       Utilize Impulse HCM 360°’s filtering capabilities to view applicant CVs efficiently and make informed hiring decisions.`,
    icon: icon6
  },
  {link: `/impulse-hcm-360/task-management`,
    title: `Task Management`,
    description:
      `Create and assign tasks to your teams, effortlessly track their progress, and generate insightful reports.
       Link tasks to evaluation forms, promoting holistic performance management.`,
    icon: icon7
  },


  {    link: `/impulse-hcm-360/work-days-notice-and-warning-management`,
  title: `Work Days Notice and Warning Management`,
  description:
    `Create and send notices and warnings to employees via email and notifications. Design and print notice
     and warning forms within Impulse HCM 360°.`,
  icon: icon12
},
  {    link: `/impulse-hcm-360/loans-management`,
    title: `Loans Management`,
    description:
      `Register and approve employee loans while specifying duration and interest rate. Impulse HCM 360° 
      automates loan deductions in the payroll, simplifying the repayment process for both parties.`,
    icon: icon9
  },
  { link: `/impulse-hcm-360/benefits-and-expenses-tracking`,
    title: `Benefits & Expenses Tracking`,
    description:
      `Create unlimited custom benefits and track payments, including travel tickets. Register and approve
       employee expenses seamlessly through Impulse HCM 360°’s interface.`,
    icon: icon10
  },
  {    link: `/impulse-hcm-360/letter-and-forms`,
  title: `Letters & Forms`,
  description:
    `Design professional letters, including contracts and other official documents. Let Impulse HCM 360° 
    automatically populate employee information, streamlining the printing process.`,
  icon: icon8
},
  {    link: `/impulse-hcm-360/custody-management`,
    title: `Custody Management`,
    description:
      `Assign and track employee custodies with Impulse HCM 360° software. Generate QR codes for custodies,
       facilitating assignment and return tracking of assets and documents.`,
    icon: icon11
  },

  { link: `/impulse-hcm-360/workflow-and-approvals`,
    title: `Workflow & Approvals`,
    description:
      `Define unlimited approval levels for various requests and decisions. Customize statuses and actions for
       each workflow, ensuring efficient and streamlined processes throughout your organization.`,
    icon: icon13
  },
  {link: `/impulse-hcm-360/memo-communication`,
    title: `Memo Communication`,
    description:
      `Create and send memos to your team via email or mobile notifications. Keep track of the read status
       for each memo, promoting effective internal communication and feedback. Use reminders to enhance your memos.`,
    icon: icon14
  },
  { link: `/impulse-hcm-360/decision-management`,
    title: `Decision Management`,
    description:
      `Effectively manage, approve, and track decisions such as employee transfers, salary adjustments, and deductions.
       Impulse HCM 360° simplifies decision-making processes for seamless operations.`,
    icon: icon15
  },
  {  link: `/impulse-hcm-360/end-of-service`,
    title: `End-of-Service`,
    description:
      `Calculations Automatically calculate entitlements, end-of-service rewards, leave balances, and obligations for
       employees based on labor laws. Ensure compliance and accuracy effortlessly.`,
    icon: icon16
  },
  {   link: `/impulse-hcm-360/insightful-dashboard`,
    title: `Insightful Dashboard`,
    description:
      `Create and customize your personalized dashboard with rich diagrams, charts, and tables. Gain valuable insights
       and make informed decisions promptly with our user-friendly interface.`,
    icon: icon17
  },
  {
    link: `/impulse-hcm-360/automated-rules-and-regulations`,
    title: `Automated Rules & Regulations`,
    description:
      `Define organizational rules and regulations, empowering Impulse HCM 360° to automatically apply penalties 
      for violations, streamline compliance effortlessly, and ensure accuracy.`,
    icon: icon18
  }
];

const AllSolutionsRe = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
         
      {features.map((val, i) => (
        <>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12 ' key={i}>
            <ScrollAnimation
              animateIn='fadeInUp'
              animateOut='fadeInOut'
              animateOnce={true}
            >
              <div
                className={`service ${serviceStyle} ${textAlign}  `}
              >
                <div className='icon w-25 m-auto p-3'>
                 <LazyLoad><img
                    src={val.icon}
                    alt={`${val.title} icon`}
                    className='w-100'
                    style={{ width: 'auto', height: 'auto' }}
                  /></LazyLoad> 
                </div>
                <div className='content all-solution-divs-height'>
                <Link to={val.link}>
                  <h4
                    className='title hcm_hover'
                    dangerouslySetInnerHTML={{ __html: val.title }}
                    style={{fontSize:'2rem'}}
                  /> </Link>
                  <p
                    className='description b1 color-gray mb--0'
                    dangerouslySetInnerHTML={{ __html: val.description }}
                    style={{textAlign:'center'}}
                  ></p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </>
      ))}
    </div>
  );
};
export default AllSolutionsRe;
