import PropTypes from "prop-types";
import React from "react";

const SEO = ({ children }) => {
  return (
    <>
        <head>{children}</head>
     
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
