import React from 'react'
import { HcmData } from './HcmData';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import { useParams } from 'react-router-dom'
import { Link } from "react-router-dom";
import SEO from '../common/SEO';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoad from 'react-lazyload';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import Separator from '../elements/separator/Separator';
import HeroFeature from '../components/HeroFeature/HeroFeature';
import SectionTitle from '../elements/sectionTitle/SectionTitle';


const HcmPages = () => {
    const { hcm } = useParams()
    const newhcm = HcmData.filter((v) => v.hcm === hcm);
    console.log();
    return (
        <>
            <HeaderOne />
            <div className='rwt-portfolio-details rn-section-gap '>
                <div className='container services-main-div'>
                    <div className='row'>
                        {newhcm.map(
                            ({

                                list,
                                para,
                                listTools,
                                listThird,
                                listFeatures,
                                listCertificate,
                                listbutton,
                                listForSeo
                            }) => (
                                <>
                                    <SEO>
                                        {listForSeo.map(({ id, title, content, link }) => (

                                            <React.Fragment key={id}>
                                                <title>{title}</title>
                                                <meta name='description' content={content} />
                                                <meta name='robots' content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' />
                                                <link rel='canonical' href={link} />
                                            </React.Fragment>
                                        ))}
                                    </SEO>

                                    <div className='col-lg-12 '>
                                        {/* breadcrumb */}
                                        {para.map(({ title }) => (
                                            <BreadcrumbOne
                                                title={title}
                                                rootUrl='/'
                                                parentUrl='Home'
                                                currentUrl='Impulse HCM 360°'
                                            />
                                        ))}
                                        {/* second section */}
                                        <div className='slider-area slider-style-3 height-450'>
                                            <div className='container'>
                                                {list !== undefined &&
                                                    list.map(({ id, title, para, para1, img }) => (
                                                        <div className='row row--30 align-items-center'>

                                                            <div className='order-2 order-lg-1 col-lg-7 mt_md--0 mt_sm--0 mt_lg--30'>
                                                                <div className='inner text-start'>
                                                                    {title && (
                                                                        <h1 className='title ' >
                                                                            {title}
                                                                        </h1>
                                                                    )}

                                                                    <p className='description'>{para}</p>

                                                                </div>
                                                            </div>
                                                            <div className='order-1 order-lg-2 col-lg-5 d-flex justify-content-center align-items-center'>
                                                            <LazyLoad><img
                                                                    src={img}
                                                                    alt='laptop img'
                                                                    height={400}
                                                                    width={600}
                                                                    style={{ maxWidth: '100%' }}
                                                               /></LazyLoad>  
                                                            </div>

                                                        </div>))}
                                            </div>
                                        </div>
                                        <Separator />
                                        {/* features section third */}
                                        {listThird !== undefined &&
                                            listThird.map(({ head, para }) => (
                                                <section className='container'  >
                                                    <br />
                                                    <div className='page-heading text-center'>
                                                        <h2 style={{ fontSize: '32px', textAlign:'center' }}>{head}</h2>
                                                        <span >{para}</span>
                                                    </div>
                                                </section>

                                            ))}

                                        {listFeatures !== undefined &&
                                            listFeatures.map((feature, index) => (
                                                <section className="container">
                                                    <HeroFeature
                                                        key={index}
                                                        img={feature.img}
                                                        title={feature.title}
                                                        body={feature.body}
                                                        reverse={feature.reverse}
                                                    />
                                                </section>

                                            ))}
                                        {/* keyfeatures */}
                                        <div className='rwt-timeline-area rn-section-gap'>
                                            {listCertificate !== undefined &&
                                                listCertificate.map((sectionTitle, index) => (
                                                    <SectionTitle
                                                        key={index}
                                                        textAlign='text-center'
                                                        radiusRounded=''
                                                        title={sectionTitle.title}

                                                    />
                                                ))}
                                            {listTools !== undefined &&
                                                listTools.map((data, index) => (<div className="row">
                                                    <div className="col-lg-12">
                                                        <div className='rn-timeline-wrapper timeline-style-one position-relative'>
                                                            <div className='timeline-line'></div>
                                                            {/* Start Single Timeline  */}


                                                            <div className='single-timeline mt--15' key={index}>
                                                                <div className='timeline-dot'>
                                                                    <div className='time-line-circle'></div>
                                                                </div>
                                                                <div className='single-content'>
                                                                    <div className='inner'>
                                                                        <div className='row row--30 align-items-center'>
                                                                            <div className='order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--10'>
                                                                                <div className='content'>
                                                                                    <ScrollAnimation
                                                                                        animateIn='fadeInUp'
                                                                                        animateOut='fadeInOut'
                                                                                        animateOnce={true}
                                                                                    >
                                                                                        <h3 className='title  text-center' style={{ fontSize: '22px' }}>{data.title}</h3>
                                                                                    </ScrollAnimation>

                                                                                    <ScrollAnimation
                                                                                        animateIn='fadeInUp'
                                                                                        animateOut='fadeInOut'
                                                                                        animateOnce={true}
                                                                                    >
                                                                                        <p className='description text-center' style={{ textAlign: 'justify' }}>{data.description}</p>
                                                                                    </ScrollAnimation>
                                                                                </div>
                                                                            </div>
                                                                            <div className='order-1 order-lg-2 col-lg-6'>
                                                                                <div className='thumbnail'>
                                                                                    <LazyLoad>
                                                                                        <img
                                                                                            style={{ width: '1100px', height: '200px' }}
                                                                                            src={data.image}
                                                                                            alt={data.title}
                                                                                        />
                                                                                    </LazyLoad>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            {/* End Single Timeline  */}
                                                        </div>
                                                    </div>
                                                </div>
                                                ))}
                                        </div>
                                    </div>
                                    {/* <Separator /> */}
                                    <section className='container mt-3'>
                                        <div className='page-heading'>
                                            {listbutton !== undefined &&
                                                listbutton.map(({ title }, index) => (
                                                    <span key={index}>{title}</span>
                                                ))}

                                            {/* <span>For more information please request a demo</span> */}

                                            <div className='read-more-btn mt--10'>
                                                <Link className='btn-default' to='/req-a-demo'>
                                                    <a>Request a Demo</a>
                                                </Link>
                                            </div>
                                            <br />
                                        </div>
                                    </section>
                                </>
                            )
                        )}
                    </div> {/* row */}
                </div>  {/* container */}


                <CalltoActionFive />
            </div>

            <FooterTwo />
            <Copyright />
        </>
    )
}

export default HcmPages