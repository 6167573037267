import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import graphic from '../../assets/images/JPGs/Graphic designing.webp';
import app from '../../assets/images/JPGs/app development-01.webp';
import web from '../../assets/images/JPGs/Web and App-01.webp';
const AdvanceTabOne = () => {
  return (
    <div>
      <Tabs>
        <div className='row row--30'>
          <div className='order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--15'>
            <div className='advance-tab-button advance-tab-button-1'>
              <TabList className='tab-button-list'>
                <Tab>
                  <div className='tab-button'>
                    <Link className='title' to='/courses/web-application-development'>Web App Development</Link>
                    <p className='description'>
                    Craft stunning websites that drive results. Master HTML, CSS, JavaScript, and frameworks like React or Vue.js. 
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className='tab-button'>
                    <Link className='title' to='/courses/android-app-development'>
                     Android App Development
                   </Link>
                    <p className='description' style={{fontSize:'16px'}}>
                    Design and build captivating mobile apps for iOS and Android. Learn Swift, Kotlin, React Native, and industry best practices.
                    </p> 
                    
                  </div>
                </Tab>
                <Tab>
                  <div className='tab-button'>
                    <Link className='title' to='/courses/graphic-designing'>
                      Graphic Designing
                    </Link>
                    <p className='description' style={{fontSize:'16px'}}>
                    Polish your creative vision. Master Photoshop, Illustrator, branding, and UI/UX design, and become a visual storytelling pro.
                    </p>
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
          <div className='order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12'>
            <div className='advance-tab-content advance-tab-content-1'>
              <TabPanel>
                <div className='tab-content'>
                  <div className='inner'>
                    <div className='thumbnail'>
                      <LazyLoad><img
                          src={web}
                          alt='Web development course by ZAI SYSTEMS'
                          // className='mt-5'
                          // style={{height:'490px'}}
                        />
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className='tab-content'>
                  <div className='inner'>
                    <div className='thumbnail'>
                      <LazyLoad><img
                          src={app}
                          // style={{height:'auto', width:'auto'}}
                          alt='App development Course by ZAI SYSTEMS'
                        />
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className='tab-content'>
                  <div className='inner'>
                    <div className='thumbnail'>
                      <LazyLoad><img
                          src={graphic}
                          // style={{height:'490px'}}
                          alt='Graphic designing Course by ZAI SYSTEMS'
                        />
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default AdvanceTabOne;
