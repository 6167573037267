import React from 'react';
import LazyLoad from 'react-lazyload';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Image from '../../assets/images/tab/int.webp';
const TabTwo = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <Tabs>
            <div className='row row--30 align-items-center'>
              <div className='col-lg-5'>
                <LazyLoad><img
                    className='radius-small'
                    src={Image}
                    alt='Corporate React Template'
                  />
                </LazyLoad>
              </div>
              <div className='col-lg-7 mt_md--40 mt_sm--40'>
                <div className='rn-default-tab style-two'>
                  <div className='tab-button-panel'>
                    <TabList className='tab-button'>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Partner</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Approach</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Deliver</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className='tab-content-panel'>
                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner'>
                          <h4>Partner With Clients</h4>
                          <p>
                            Our clients find us a true companion in every
                            business deal. Our priority is to provide best
                            practices for your business.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner'>
                          <h4>Approach</h4>
                          <p>
                            We ensure client centric approach in our services by
                            understanding their pain points and giving solutions
                            according to it.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner'>
                          <h4>Deliver</h4>
                          <p>
                            Our fundamental corporate values aim to provide
                            quality services that ultimately lead towards
                            business success.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabTwo;
