import React from 'react';
// import Lottie from 'react-lottie';
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import webAnimation from '../data/webAnimation.json';
import AboutThree from '../elements/about/AboutThree';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import Separator from '../elements/separator/Separator';
// import LazyLoad from "react-lazyload";
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';

const Business = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: webAnimation
  };
  return (
    <>
      <SEO>
        <title>FAQs | ZAI SYSTEMS</title>
        <meta
          name='description'
          content='See Frequently Asked Questions about ZAI SYSTEMS business and services.'
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/faq-page' />
      </SEO>
      <main className='page-wrapper'>
        <HeaderOne btnStyle='btn-small' HeaderSTyle='header-not-transparent' />
        {/* Start Slider Area  */}
        <BreadcrumbOne
                    title='FAQS'
                    rootUrl='/'
                    parentUrl='Home'
                    currentUrl='FAQS'
                />
        {/* <div className='slider-area slider-style-3 height-650'>
          <div className='container'>
            <div className='row row--30 align-items-center'>
              <div className='order-2 order-lg-1 col-lg-5 mt_md--50 mt_sm--50 mt_lg--30'>
                <div className='inner text-start'>
                  <h1 className='title theme-gradient'>FAQS</h1>
                  <p className='description'>Ask Your Queries if Any !</p>
                </div>
              </div>
              <div className='order-1 order-lg-2 col-lg-7'>
                
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Slider Area  */}
        <Separator />
        <AboutThree />
        {/* Start Elements Area  */}
        <div className='rwt-callto-action-area rn-section-gapBottom'>
          <div className='wrapper'>
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Business;
