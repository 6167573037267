import React from 'react';
import lightIcon from "../../assets/images/icons/sun-01.svg"
import darkIcon from "../../assets/images/icons/vector.svg"
import LazyLoad from "react-lazyload";

const Darkmode = () => {
    const switchTheme = (e) => {
        document.querySelector('body').classList.toggle("light")
    }
    return (
        <button id="darkmode" onClick={(e) => switchTheme(e)}>
           <LazyLoad><img className="light-icon" src={lightIcon} alt="light-Icon" /></LazyLoad> 
           <LazyLoad><img className="dark-icon" src={darkIcon} alt="dark-Icon" /></LazyLoad> 
        </button>
    )
}
export default Darkmode;