import React from 'react';
import Layout from '../common/Layout';
import SEO from '../common/SEO';
import PortfolioData from '../data/portfolio/PortfolioData.json';
import PortfolioDetailsContent from '../elements/portfolio/PortfolioDetailsContent';

const PortfolioDetails = ({
  match: {
    params: { category }
  }
}) => {
  const data = PortfolioData.filter(
    (portfolio) => portfolio.category === category
  );

  return (
    <>
      <SEO>
        <title>{data[0].metaTitle}</title>
        <meta name='description' content={data[0].description} />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href={window.location.href} />
      </SEO>
      <Layout>
        <PortfolioDetailsContent data={data[0]} />
      </Layout>
    </>
  );
};

export default PortfolioDetails;
